/*
    系统设置路由
 */
export default [
  {
    path: "/infectiousDisease",
    meta: {
      title: "疾病防控",
    },
    component: () => import("@/layout/Main"),
    hidden: false,
    children: [
      {
        // 疾病防控列表-总部
        path: "/infectiousDiseaseList",
        name: "infectiousDiseaseList",
        meta: {
          title: "疾病防控列表/总部",
          keepAlive:true,
        },
        hidden: false,
        component: () =>
          import("@/views/infectiousDisease/infectiousDiseaseList/index.vue"),
      },
      {
        // 疾病防控列表-分公司
        path: "/infectiousDiseaseListCompany",
        name: "infectiousDiseaseListCompany",
        meta: {
          title: "疾病防控列表/分公司",
          keepAlive:true,
        },
        hidden: false,
        component: () =>
          import(
            "@/views/infectiousDisease/infectiousDiseaseList/infectiousDiseaseListCompany.vue"
          ),
      },
      {
        // 疾病防控列表-园校
        path: "/infectiousDiseaseListSchool",
        name: "infectiousDiseaseListSchool",
        meta: {
          title: "疾病防控列表/园校",
          keepAlive:true,
        },
        hidden: false,
        component: () =>
          import(
            "@/views/infectiousDisease/infectiousDiseaseList/infectiousDiseaseListSchool.vue"
          ),
      },
      {
        // 疾病防控上报
        path: "/diseaseReport",
        name: "diseaseReport",
        meta: {
          title: "疾病防控",
        },
        hidden: true,
        component: () =>
          import("@/views/infectiousDisease/diseaseReport/report.vue"),
      },
      {
        // 总部审核
        path: "/headquartersCheck",
        name: "headquartersCheck",
        meta: {
          title: "总部审核",
        },
        hidden: true,
        component: () =>
          import("@/views/infectiousDisease/headquartersCheck/audit.vue"),
      },
      {
        // 结束反馈
        path: "/endback",
        name: "endback",
        meta: {
          title: "结束反馈",
        },
        hidden: true,
        component: () =>
          import("@/views/infectiousDisease/endback/endback.vue"),
      },
      {
        // 查看
        path: "/lookover",
        name: "lookover",
        meta: {
          title: "查看",
        },
        hidden: true,
        component: () =>
          import("@/views/infectiousDisease/lookover/lookover.vue"),
      },
    ],
  },
];
