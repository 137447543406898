// // 手机号验证
// export function isvalidPhone(str) {
//     const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
//     return reg.test(str);
//   }

//   // 验证密码   密码，以字母开头，长度在8~18之间，只能包含字母、数字和下划线
export function validPass(str) {
  const reg = /^[a-zA-Z]\w{8,16}$/;
  return reg.test(str);
}

//   //   验证用户名  用户名要求 数字、字母、下划线的组合，其中数字和字母必须同时存在*
export function validUsername(str) {
  const reg = /^(?![^A-Za-z]+$)(?![^0-9]+$)[0-9A-Za-z_]{4,15}$/;
  return reg.test(str);
}

//只能输入数字、字母、汉字
export function noSymbol(str) {
  const reg = /^[0-9a-zA-Z\u4e00-\u9fa5]+$/;
  return reg.test(str);
}

//校验视频链接
export function videoUrlAuth(str) {
  const reg = /^https?.+\.(mp4|mp3|avi)$/i;
  return reg.test(str);
}

//不能连续字符校验
export function validNoContinue(str) {
  let arr = str.split("");
  let flag = true;
  if (arr.length < 6) {
    return flag;
  }
  let oneIndex = arr[0].charCodeAt();
  let twoIndex = arr[1].charCodeAt();
  let threeIndex = arr[2].charCodeAt();
  // let fourIndex = arr[3].charCodeAt();
  // let fiveIndex = arr[4].charCodeAt();
  // let sixIndex = arr[5].charCodeAt();
  if (threeIndex - twoIndex === 1 && twoIndex - oneIndex === 1) {
    //(sixIndex - fiveIndex === 1)&&(fiveIndex - fourIndex === 1)&&(fourIndex - threeIndex === 1)&&
    flag = false;
  } else if (twoIndex - threeIndex === 1 && oneIndex - twoIndex === 1) {
    //(fiveIndex - sixIndex === 1)&&(fourIndex - fiveIndex === 1)&&(threeIndex - fourIndex === 1)&&
    flag = false;
  }
  return flag;
}

//不能重复字符校验
export function validNoSame(str) {
  let arr = str.split("");
  let flag = true;
  if (arr.length < 6) {
    return flag;
  }
  let oneIndex = arr[0].charCodeAt();
  let twoIndex = arr[1].charCodeAt();
  let threeIndex = arr[2].charCodeAt();
  // let fourIndex = arr[3].charCodeAt();
  // let fiveIndex = arr[4].charCodeAt();
  // let sixIndex = arr[5].charCodeAt();
  if (threeIndex === twoIndex && twoIndex === oneIndex) {
    //(sixIndex === fiveIndex)&&(fiveIndex === fourIndex)&&(fourIndex === threeIndex)&&
    flag = false;
  }
  return flag;
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
export function handleTree(
  data,
  id,
  parentId,
  children = "children",
  rootId = "0"
) {
  id = id || "id";
  parentId = parentId || "parentId";
  children = children || "children";
  //对源数据深度克隆
  const cloneData = JSON.parse(JSON.stringify(data));
  //循环所有项
  const treeData = cloneData.filter((father) => {
    let branchArr = cloneData.filter((child) => {
      //返回每一项的子级数组
      return father[id] === child[parentId];
    });
    branchArr.length > 0 ? (father.children = branchArr) : "";
    //返回第一层
    return father[parentId] === rootId;
  });
  return treeData != "" ? treeData : data;
}

/**
 * @title 全部展开
 *
 **/
export function unwindAll() {
  const els = document.getElementsByClassName("el-table__expand-icon");
  for (let i = 0; i < els.length; i++) {
    !els[i].getAttribute("class").includes("el-table__expand-icon--expanded")
      ? els[i].click()
      : "";
  }
}

/**
 * @title 全部收缩
 *
 **/
export function shrinkAll() {
  const els = document.getElementsByClassName(
    "el-table__expand-icon--expanded"
  );
  for (let i = 0; i < els.length; i++) {
    els[i].click();
  }
}

/**
 * 日期格式化
 */
export function dateFormat(date) {
  let format = "yyyy-MM-dd hh:mm:ss";
  if (date != "Invalid Date") {
    var o = {
      "M+": date.getMonth() + 1, //month
      "d+": date.getDate(), //day
      "h+": date.getHours(), //hour
      "m+": date.getMinutes(), //minute
      "s+": date.getSeconds(), //second
      "q+": Math.floor((date.getMonth() + 3) / 3), //quarter
      S: date.getMilliseconds(), //millisecond
    };
    if (/(y+)/.test(format))
      format = format.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    for (var k in o)
      if (new RegExp("(" + k + ")").test(format))
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length == 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
    return format;
  }
  return "";
}

/**
 * 日期格式化 yyyy-MM-dd
 */
export function dateFormatDD(date) {
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  var nowTime = y + "-" + m + "-" + d;

  return nowTime;
}
/*计算天数差的函数*/
export function dateDiff(sDate1, sDate2) {
  //sDate1和sDate2是2006-12-18格式
  let date1 = new Date(sDate1);
  let date2 = new Date(sDate2);
  date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
  date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
  const diff = date2.getTime() - date1.getTime(); //目标时间减去当前时间
  const diffDate = diff / (24 * 60 * 60 * 1000);
  return diffDate;
}

/*获取传入日期前后多少天的日期，之前多少天传正数，后面多少天传负数，今天传0，*/
export function timeDiff(num, time) {
  let n = parseInt(num) - parseInt(num) * 2; // 正 <=> 负
  let d = "";
  if (time) {
    d = new Date(time);
  } else {
    d = new Date();
  }
  let year = d.getFullYear();
  let mon = d.getMonth() + 1;
  let day = d.getDate();
  if (day <= n) {
    if (mon > 1) {
      mon = mon - 1;
    } else {
      year = year - 1;
      mon = 12;
    }
  }
  d.setDate(d.getDate() - n);
  year = d.getFullYear();
  mon = d.getMonth() + 1;
  day = d.getDate();
  let s =
    year +
    "-" +
    (mon < 10 ? "0" + mon : mon) +
    "-" +
    (day < 10 ? "0" + day : day);
  return s;
}

/**除法运算 */
export function floatp(arg1, arg2) {
  var t1 = 0,
    t2 = 0,
    r1,
    r2;

  try {
    t1 = arg1.toString().split(".")[1].length;
  } catch (e) { }

  try {
    t2 = arg2.toString().split(".")[1].length;
  } catch (e) { }

  r1 = Number(arg1.toString().replace(".", ""));

  r2 = Number(arg2.toString().replace(".", ""));

  return (r1 / r2) * Math.pow(10, t2 - t1);
}


// 防抖
let timeout = null
export function testFun(fun, time = 1000) {
  if (timeout) {
    clearTimeout(timeout) // 规定时间内若定时器存在则清除
  }
  timeout = setTimeout(() => {
    console.log('触发点击')
    fun() // 调用接口
  }, time);
}

//echarts 名字过长省略号显示，hover显示全部
export function extension(chart) {
  // 注意这里，是以X轴显示内容过长为例，如果是y轴的话，需要把params.componentType == 'xAxis'改为yAxis
  // 判断是否创建过div框,如果创建过就不再创建了
  // 该div用来盛放文本显示内容的，方便对其悬浮位置进行处理
  var elementDiv = document.getElementById('extension')
  if (!elementDiv) {
    var div = document.createElement('div')
    div.setAttribute('id', 'extension')
    div.style.display = 'block'
    document.querySelector('html').appendChild(div)
  }
  chart.on('mouseover', function (params) {
    if (params.componentType == 'xAxis') {
      var elementDiv = document.querySelector('#extension')
      //设置悬浮文本的位置以及样式
      var elementStyle =
        'position: absolute;z-index: 99999;color: #fff;font-size: 12px;padding: 5px;display: inline;border-radius: 4px;background-color: #303133;box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 8px'
      elementDiv.style.cssText = elementStyle
      elementDiv.innerHTML = params.value
      document.querySelector('html').onmousemove = function (event) {
        var elementDiv = document.querySelector('#extension')
        var xx = event.pageX + 10
        var yy = event.pageY - 30
        elementDiv.style.top = yy + 'px'
        elementDiv.style.left = xx + 'px'
      }
    }
  })
  chart.on('mouseout', function (params) {
    //注意这里，我是以X轴显示内容过长为例，如果是y轴的话，需要改为yAxis
    if (params.componentType == 'xAxis') {
      var elementDiv = document.querySelector('#extension')
      elementDiv.style.cssText = 'display:none'
    }
  })
}

//   import Vue from 'vue'
// import VeeValidate, { Validator } from 'vee-validate'
// import zh from 'vee-validate/dist/locale/zh_CN' // 引入中文文件
// // Validator.addLocale(zh)
// const config = {
//     locale: 'zh_CN',
//     fieldsBagName: 'fieldBags'
// }
// Vue.use(VeeValidate, config)

// // 自定义validate
// const dictionary = {
//     zh_CN: {
//         messages: {
//             required: (field) => '请' + field,
//             number: (field) => field + '必须为数值',
//             url: () => '请输入正确格式的服务器地址',
//             ip: () => '请输入正确的IP地址',
//         },
//         attributes: {
//             ip: '输入IP地址',
//             httpPort: '输入HTTP端口',
//             userName: '输入用户昵称',
//             mobile: '输入手机号码',
//             emailAdd: '输入邮件地址',
//             authCode: '输入您收到的验证码',
//             loginPwd: '输入登录密码（6~18位',
//             traderpwd: '输入交易密码（6~18位',
//             recommender: '输入您的推荐人',
//             isSee: '阅读用户协议，并同意',
//             npwdLog: '输入新的登录密码',
//             qrwdLog: '输入确认登录密码'
//         }
//     }
// }
// Validator.updateDictionary(dictionary)

// // 1.IPV4地址
// Validator.extend('ipAddr', {
//     messages: {
//         zh_CN: field => '请输入正确的IPV4地址'
//     },
//     validate: value => {
//         return /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/.test(value)
//     }
// })

// // 2.端口【0~65535】
// Validator.extend('port', {
//     messages: {
//         zh_CN: field => '请输入正确格式的端口号'
//     },
//     validate: value => {
//         return /^([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{4}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/.test(value)
//     }
// })

// // 3.用户昵称
// Validator.extend('name', {
//     messages: {
//         zh_CN: field => '用户昵称不允许特殊符号'
//     },
//     validate: value => {
//         return /^[\u4e00-\u9fa5A-Za-z0-9]*$/.test(value)
//     }
// })

// // 4.用户昵称
// Validator.extend('phone', {
//     messages: {
//         zh_CN: field => '手机号格式不正确'
//     },
//     validate: value => {
//         return /^1[2-9]\d{9}$/.test(value)
//     }
// })

// // 5.邮箱
// Validator.extend('email', {
//     messages: {
//         zh_CN: field => '邮箱格式不正确'
//     },
//     validate: value => {
//         return /^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$/.test(value)
//     }
// })

// // 6.验证码
// Validator.extend('code', {
//     messages: {
//         zh_CN: field => '验证码格式不正确'
//     },
//     validate: value => {
//         return /^[0-9]*$/.test(value)
//     }
// })

// // 6.登录密码
// Validator.extend('logpwd', {
//     messages: {
//         zh_CN: field => '登录密码格式不正确'
//     },
//     validate: value => {
//         return /^\S{6,18}$/.test(value)
//     }
// })

// // 7.交易密码
// Validator.extend('tradpwd', {
//     messages: {
//         zh_CN: field => '交易密码格式不正确'
//     },
//     validate: value => {
//         return /^\S{6,18}$/.test(value)
//     }
// })

// // 8.推荐人
// Validator.extend('recom', {
//     messages: {
//         zh_CN: field => '推荐人格式不正确'
//     },
//     validate: value => {
//         return /^[^\u4e00-\u9fa5]{0,}$/.test(value)
//     }
// })

// // 9.用户协议
// Validator.extend('see', {
//     messages: {
//         zh_CN: field => '请阅读用户协议，并同意'
//     },
//     validate: value => {
//         return true
//     }
// })

// // 10.忘记密码，新的登录密码
// Validator.extend('npwd', {
//     messages: {
//         zh_CN: field => '登录密码格式不正确[6~18位]'
//     },
//     validate: value => {
//         return /^\S{6,18}$/.test(value)
//     }
// })

// // 11.忘记密码，确认新的登录密码
// Validator.extend('qrwd', {
//     messages: {
//         zh_CN: field => '登录密码格式不正确[6~18位]'
//     },
//     validate: value => {
//         return /^\S{6,18}$/.test(value)
//     }
// })
