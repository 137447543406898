import http from "../http.interceptors";
const databaseApi = {
    /**
     *目录管理
     */
    //目录排序
    changeSortDirectory: (params) => http.put("/dataDirectory/data-directory/changeSort?id=" + params.id + '&type=' + params.type),
    //三级目录查看、编辑回显
    threeDirectoryQueryOrUpdate: (params) => http.get("/dataDirectory/data-directory/getById/" + params),
    //目录树查询
    getDirectoryTree: () => http.get("/dataDirectory/data-directory/getInfoForTree"),
    //二级目录查询
    getSecDirectory: () => http.get("/dataDirectory/data-directory/getSecDirectory"),
    //三级目录查询
    getThirdDirectory: () => http.get("/dataDirectory/data-directory/getThirdDirectory"),
    //分页目录查询
    getSortPageDirectory: (params) => http.get("/dataDirectory/data-directory/queryList",{params}),
    //删除目录
    delDirectory: (params) => http.post("/dataDirectory/data-directory/remove/" + params.id),
    //保存/编辑目录
    saveOrUpdateDirectory: (params) => http.post("/dataDirectory/data-directory/saveOrUpdate", params),
    //  可见范围列表
    getPostForTree: (params) => http.get("/mng/post/getPostForTree?type=0", params),
    //  20221020-修改可见范围列表接口
    getDeptForTree: () => http.get("/mng/dept/getDeptForTree"),

    /*文件管理*/
    //获取文件列表
    getFileList: (params) => http.get("/dataDirectory/data-file/queryList", { params }),
    //获取附件信息
    getAttachmentInfo: (params) => http.get("/dataDirectory/data-attachment/getById/" + params.id),
    //获取回滚信息
    getFileInfoByVersionId: (params) => http.get("/dataDirectory/data-file/getFileInfoByVersionId", { params }),
    //获取历史版本附件
    getAttachmentVersionInfo: (params) => http.get("/dataDirectory/data-attachment/getAttachmentByVersionId", { params }),
    //获取版本记录
    getVersionNumberLog: (params) => http.get("/dataDirectory/data-file-version/queryByFileId", { params }),
    //获取文件信息
    getFileInfo: (params) => http.get("/dataDirectory/data-file/getInfoById", { params }),
    //获取文件版本信息
    getFileVersionList: (params) => http.get("/dataDirectory/data-file/getInfoById", { params }),
    //下载指定文件
    downloadFile: (params) => http.get("/dataDirectory/data-attachment/downloadFile?id=" + params.id),
    //打包下载
    packDownloadFile: (params) => http.get("/dataDirectory/data-attachment/batchDownloadFile?id=" + params.id),
    // 查询文件操作日志
    getFileHandleLog: (params) => http.get("/dataDirectory/data-operation-log/queryLogByFileId", { params }),
    //保存文件信息 
    saveOrUpdateFileInfo: (params) => http.post("/dataDirectory/data-file/saveOrUpdate", params),
    //删除文件信息  
    delFile: (params) => http.post("/dataDirectory/data-file/remove?ids=" + params.id),
    //查看附件
    onlinePreview: (params) => http.post("/dataDirectory/data-attachment/onlinePreview?id=" + params.id),
    //回收站
    //查询
    getRecycleList: (params) => http.get("/dataDirectory/data-file/queryRecycle", { params }),
    //恢复文件  
    recoveryFile: (params) => http.put(`/dataDirectory/data-file/resetFile?id=${params.id}&directoryId=${params.directoryId}&type=${params.type}`),
    //删除回收站文件
    delRecycleFiles: (params) => http.post(`/dataDirectory/data-file/removeRecycle?ids=${params.id}&type=${params.type}`),
    
    // 图标
    // 图标列表 
    getIconList: (params) => http.get("/dataDirectory/iconLibrary/icon-library/page", { params }),
    // 添加图标
    addIcon: (params) => http.post("/dataDirectory/iconLibrary/icon-library/saveOrUpdate" , params),
    // 删除图标
    delIcon: (params) => http.post("/dataDirectory/iconLibrary/icon-library/remove/" + params),
    // 获取图标详情
    getIconDetail: (params) => http.get("/dataDirectory/iconLibrary/icon-library/getById/" + params),
    // 上传图片
    uploadIconImg:(params) => http.post("/dataDirectory/data-attachment/uploadImg", params),

}
export { databaseApi };