import http from "../http.interceptors";

const attendanceApi = {
  /* 出勤 */

  //查询出勤_园校
  getAttendanceByMonth: (params) =>
    http.post("/mng/attendance/attendance/getAttendanceInfoByMonth", params),

  //查询出勤_园校-保存出勤
  getAttendanceSave: (params) =>
    http.post("/mng/attendance/attendance/save", params),

  //查询出勤_分公司
  getAttendanceInfoByMonthForCom: (params) =>
    http.post(
      "/mng/attendance/attendance/getAttendanceInfoByMonthForCom",
      params
    ),

  //查询出勤_总部
  getAttendanceInfoByMonthForHQ: (params) =>
    http.post(
      "/mng/attendance/attendance/getAttendanceInfoByMonthForHQ",
      params
    ),
  //查询出勤_总部下的分公司
  getAttendanceInfoByMonthForHQNew: (params) =>
  // attendance/attendance/getAttendanceInfoByMonthForHQCom
    http.post(
      "/mng/attendance/attendance/getAttendanceInfoByMonthForHQCom",
      params
    ),
  //查询出勤_分公司下的园校
  getAttendanceInfoByMonthForHQComSchool: (params) =>
    http.post(
      "/mng/attendance/attendance/getAttendanceInfoByMonthForHQComSchool",
      params
    ),
    // 
  //查询出勤_总部合计
  getRateByMonthForHQSum: (params) =>
    http.post("/mng/attendance/attendance/getRateByMonthForHQ", params),

  //查询出勤_分公司
  getRateByMonthForComSum: (params) =>
    http.post("/mng/attendance/attendance/getRateByMonthForCom", params),

  //查询出勤_园校
  getRateByMonthSchSum: (params) =>
    http.post("/mng/attendance/attendance/getRateByMonth", params),
  //   园校：/attendance/attendance/getRateByMonth
  // 分公司：/attendance/attendance/getRateByMonthForCom
  // 总部：/attendance/attendance/getRateByMonthForHQ
};

export { attendanceApi };
