import http from "./http.interceptors";
import axios from "axios";
import { foodExpenseApi } from "./modules/http.api.foodExpense"; //伙食费
import { infectiousDiseaseApi } from "./modules/http.api.infectiousDisease"; //疾病防控
import { insuranceApi } from "./modules/http.api.insurance"; //保险
import { attendanceApi } from "./modules/http.api.attendance"; //出勤
import { morbidityApi } from "./modules/http.api.morbidity"; //出勤
import { systemApi } from "./modules/http.api.system";
import { courseApi } from "./modules/http.api.course";
import { getToken } from "@/utils/auth";
import teachingProgram from "./modules/http.api.teachingProgram";
import { databaseApi } from "./modules/http.api.database"; //资料库
import { targetApi } from "./modules/http.api.targetManage"; //目标管理
import { opratingApi } from "./modules/http.api.opratingManage"; //运营管理
import { superviseApi } from "./modules/http.api.supervise"; //督导管理
import qs from "qs";
import { dataBoardApi } from "./modules/http.api.dataBoard"; //督导管理

const url = {
  codeUrl: "/code?randomStr=",
  getUploadUrl: "/sszagoods/goodsImg/getUploadUrl",
};

for (let key in url) {
  url[key] = process.env.VUE_APP_LOCAL_URL + url[key];
}

// url.imgUrl='http://10.86.3.202:8080'
// url.imgUrl=''
url.imgUrl = process.env.VUE_APP_IMG_URL;

const api = {
  ...superviseApi,
  ...opratingApi,
  ...targetApi,
  ...attendanceApi,
  ...databaseApi,
  ...insuranceApi,
  ...foodExpenseApi,
  ...infectiousDiseaseApi,
  ...systemApi,
  ...courseApi,
  ...teachingProgram,
  ...morbidityApi,
  ...dataBoardApi,
  //学生管理看板接口-出勤率
  getStudentAttendanceRate: (params) =>
    http.get("/report/student/attendanceRate", { params }),

  //学生管理看板接口-疾病防控
  getStudentContagionReport: (params) =>
    http.get("/report/student/contagionReport", { params }),

  //学生管理看板接口-发病率/患病率
  getStudentDiseaseRate: (params) =>
    http.get("/report/student/diseaseRate", { params }),

  //学生管理看板接口-学生流失
  getStudentStudentRunOff: (params) =>
    http.get("/report/student/studentRunOff", { params }),

  //学生出险情况
  getInsuranceByStudent: (params) =>
    http.get("/report/operate/insuranceByStudent", { params }),

  //员工出险情况
  getInsuranceByStaff: (params) =>
    http.get("/report/operate/insuranceByStaff", { params }),

  //在保率
  getOperateInsure: (params) => http.get("/report/operate/insure", { params }),

  //新园开办
  getNewSchoolPlan: (params) =>
    http.get("/report/operate/newSchoolPlan", { params }),

  //督导任务完成情况
  getSuperviseAchievementInfo: (params) =>
    http.get("/report/operate/superviseAchievementInfo", { params }),

  //督导标签类型
  getSuperviseType: (params) =>
    http.get("/report/operate/superviseType", { params }),

  //运营任务完成情况
  getOperateAchievementInfo: (params) =>
    http.get("/report/operate/operateAchievementInfo", { params }),

  //园校拓展
  getSchoolExpand: (params) =>
    http.get("/report/operate/schoolExpand", { params }),

  //满园率
  getSchoolPlanRate: (params) =>
    http.get("/report/operate/schoolPlanRate", { params }),

  //系统设置保存
  getRemindTimeSave: (params) => http.post("/remind/remind-time/update", params),

  //系统设置列表
  getRemindTimeList: (params) =>
    http.get("/remind/remind-time/list", { params }),

  //字典查询
  getDictionary: (params) => http.get("/mng/dict/list", { params }),

  //字典树查询
  getDictionaryTree: (params) => http.get("/mng/dict/tree", { params }),

  //查询组织结构类型：分公司：NODE_ORG 园校：NODE_SCHOOL
  getCompanyDept: (params) => http.get("/mng/dept/getCompanyDept", { params }),

  //查询班级
  getClassInfo: (params) => http.get("/mng/dept/getClassInfo", { params }),

  //查询所有园校
  getAllSchool: (params) => http.get("/mng/dept/getAllSchool", { params }),

  //查询可分享的组织结构
  getShareTreeInfo: (params) =>
    http.get("/mng/post/getPostForTree", { params }),

  //获取菜单权限
  getMenu: () => http.get("/mng/auth/menu"),
  // getMenu: () => http.get('/api/techauth/menuAuth'),

  //获取用户信息
  getUerInfo: () => http.get("/mng/admin/user/info"),
  //用户名登录
  // loginByUsername: params => http.post('/api/techloginuser/login', params),
  loginByUsername: (params) => http.post("/mng/loginuser/login", params),

  // 数据权限
  getDept: () => http.get("/api/edudept/deptList"),
  //权限修改
  eduauthData: (params) => http.post("/api/eduauthdata", params),
  // 修改密码
  userEdit: (params) => http.post("/api/techloginuser/update/pwd", params),

  // 重置密码
  resetPwd: (params) =>
    api.postFormRequest("/mng/loginuser/update/resetPwd", params),

  // 获取手机验证码
  // getVerificationCode: params => http.get('/loginuser/getCode', {params}),
  getVerificationCode: (params) =>
    http.get("/mng/loginuser/verificationCode", { params }),

  //获取图片校验码
  // getAuthCode: params => http.get('/api/techloginuser/getCode', {params}),
  getAuthCode: (params) => http.get("/mng/loginuser/getCode", { params }),
  // 校园的树型数据
  getSchoolForTree: (params) =>
    http.get("/mng/dept/getSchoolForTree", { params }),
  ////上传方法
  importImgFile: (url, file) => {
    let forms = new FormData();
    let configs = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };
    forms.append("file", file);

    return http.post(url, forms, configs);
  },

  postFormRequest: (url, params) => {
    let formData = new FormData();
    Object.keys(params).forEach((key) => {
      formData.append(key, params[key]);
    });
    let configs = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };
    return http.post(url, formData, configs);
  },
};

export { api, url };
