
import http from "../http.interceptors";

const systemApi = {
  /*
        应用系统管理
     */
  // 列表
  getBusiness: params => http.get('/sszagoods/sszabusiness/page', { params }),
  // 详情
  getBusinessDetail:id => http.get('/sszagoods/sszabusiness/' + id + ''),
  // 新增
  addBusiness: params => http.post('/sszagoods/sszabusiness', params),
  // 修改
  editBusiness: params => http.put('/sszagoods/sszabusiness', params),
  // 删除
  delBusiness: id => http.delete('/sszagoods/sszabusiness/' + id + ''),
  // 获取往来单位列表
  getcompanyd: data => http.get('/admin/dept/all-tree', { params: data }),
  // 用户管理
  fetchListUser: data => http.get('/admin/user/page', { params: data }), // 列表
  fetchDeptTreeUser: data => http.get('/admin/dept/user-tree', { params: data }), // 树形数据/admin/role/list
  deptRoleListUser: () => http.get('/admin/role/list'), // 角色
  addObjUser: data => http.post('/admin/user', data),
  delObjUser: data => http.delete('/admin/user/' + data),
  getObjUser: data => http.get('/admin/user/' + data),
  putObjUser: data => http.put('/admin/user', data),
  getDetailsUser: data => http.get('/admin/user/details/' + data),
  // 部门管理
  addObjRole: data => http.post('/admin/role', data),
  delObjRole: data => http.delete('/admin/role/' + data),
  fetchListRole: data => http.get('/admin/role/page', { params: data }), // 列表
  fetchRoleTreeRole: data => http.get('/admin/menu/tree/' + data),
  getObjRole: data => http.get('/admin/role' + data),
  permissionUpdRole: (roleId, menuIds) => http.put('/admin/role/menu?roleId=' + roleId + '&menuIds=' + menuIds),
  putObjRole: data => http.put('/admin/role', data),
  // 菜单管理
  addObjMenu: data => http.post('/admin/menu', data),
  delObjMenu: data => http.delete('/admin/menu/' + data),
  fetchTree: data => http.get('/admin/menu/tree', { params: data }),
  getObjMenu: data => http.get('/admin/menu/' + data),
  putObjMenu: data => http.put('/admin/menu', data),
  // 门户-更改密码、手机号
  userEdit: data => http.put('/admin/user/edit', data),
  // 门户-消息列表
  getMyNotice: data => http.get('/sszastock/notice/getMyNotice', { params: data }), // 列表
  // 门户-任务查看
  readNotice: params => http.post('/sszastock/notice/readNotice', params), // 详情
  // 部门管理-树形菜单
  fetchDeptTree: data => http.get('/admin/dept/tree', { params: data }),
  // 部门管理-添加
  addObj: params => http.post('/admin/dept/', params),
  // 部门管理-删除
  delObj: data => http.delete('/admin/dept/' + data),
  // 部门管理-更新
  putObj: params => http.put('/admin/dept/', params),


  // 登录日志管理-列表
  fetchLoginLogList: data => http.get('/api/eduLog/getLoginLog', { params: data }),
  // 登录日志管理-列表
  fetchHandleLogList: data => http.get('/sysLog/page', { params: data }),
  // fetchHandleLogList: data => http.get('/api/techLog/page', { params: data }),
  // 日志管理-删除
  delLog: data => http.delete('/admin/log/' + data),
  // 字典管理-列表
  fetchListDict: data => http.get('/mng/dict/search', { params: data }),
  // fetchListDict: data => http.get('/api/techdict/search', { params: data }),
  
  // 字典管理-更新
  putObjDict: params => http.put('/mng/dict/updateById', params),
  // 字典管理-删除
  delDict: data => http.delete('/mng/dict/remove/' + data.id),
  // 字典管理-添加
  addObjDict: params => http.post('/mng/dict/save', params),
  // 数据权限-label查询权限
  getByDeptLabel: params => http.get('/api/eduauthdata/getByDeptLabel', { params }),
  // 操作日志-查询所属模块
  getLogModule: params => http.get('/sysLog/getLogModule', { params }),
  // getLogModule: params => http.get('/api/techLog/getLogModule', { params }),
  // 获取指定类型字典
  getTechDict: params => http.get('/dict/list', { params }),
  // getTechDict: params => http.get('/api/techdict/list', { params }),
  // 关于我们/用户协议-获取
  getAboutUs: params => http.get('/api/techappinfo/getAppInfo', { params }),
  // 关于我们/用户协议-修改
  postAboutUs: params => http.post('/api/techappinfo/saveAppInfo', params),

  // 意见反馈-获取列表
  getTechfFeedback: params => http.get('/api/techfeedback/search', { params }),
  // 历史消息-获取列表
  getHistoryNews: params => http.get('/remind/remind-record/page', { params }),
  // 历史消息-获取列表
  getHistoryNewsLimit: params => http.get('/remind/remind-record/list', { params }),
  // 历史消息-获取列表
  setHistoryNewsType: params => http.post('/remind/remind-record/updateById', params),
  // 历史消息-全部已读
  readAll: params => http.post('/remind/remind-record/readAll', params),
  // 判断消息提醒页面跳转
  judgeStatus: params => http.get('/supervise/order/judgeStatus/'+params,),
  // 获取所有图标库
  getAllIconList: params => http.get('/dataDirectory/iconLibrary/icon-library/selectAll', { params }),

  //首页--获取日历待办
  getViewTask: params => http.post('/remind/homepage/monthView', params),
  //首页-目标待办任务列表
  getHomeTargetList: params => http.get('/target/info/page', { params }),
  //首页-督导待办任务列表
  getHomeSuperviseList: params => http.get('/supervise/order/pageToDoApi', { params }),
  //首页-运营待办任务列表
  getHomeOperateList: params => http.get('/operate/task-detail/page', { params }),
  //首页-运营批量待办
  batchOperateTask: params => http.post('/operate/task-execute/batchSave', params),
  //首页--督导任务更新时间
  updateSuperviseTaskTime: params => http.post('/supervise/order/updateTime', params),
  //首页--运营任务更新时间
  updateOperationTaskTime: params => http.post('/operate/task-detail/setTaskDetail', params),
  // 同步督导任务接收人和审批人
  updateSuperviseGET: params => http.get('/supervise/updateSupervise/update', { params }),


  // 刷新字典缓存
  flushRedisDictUsingGET: params => http.get('/mng/dict/flushRedisDict', { params }),
  // 组织同步
  syncDeptUsingGET: params => http.get('/mng/api/dept/syncDept', { params }),
  // 岗位同步
  syncAllPostUsingGET: params => http.get('/mng/api/post/syncAllPost', { params }),
  // 岗位同步
  syncChildAndStaff: params => http.get('/mng/insurance/info/syncChildAndStaff', { params }),
  // 清空级联岗位顺序
  clearPostOrder: params => http.get('/mng/post/cleanRedisPost', { params }),
  // 切换岗位 列表
  getPostByUser: params => http.get('/mng/loginuser/getPostByUser', {}),
  // 切换岗位 列表
  changePost: params => http.post('/mng/loginuser/changePost', params),
};

export { systemApi };
