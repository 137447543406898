/*
    系统设置路由
 */
export default [
  {
    path: "/dictManage",
    meta: {
      title: "字典管理",
    },
    component: () => import("@/layout/Main"),
    hidden: false,
    children: [
      {
        // 字典管理
        path: "/dict",
        name: "dict",
        meta: {
          title: "字典",
        },
        hidden: false,
        component: () => import("@/views/dictManage/dict/index.vue"),
      },
    ],
  },
];
