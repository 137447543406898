/*
    系统设置路由
 */
export default [
  {
    path: "/dataBoard",
    meta: {
      title: "数据看板",
    },
    component: () => import("@/layout/Main"),
    hidden: false,
    children: [
      {
        // 经营看板
        path: "/businessBoard",
        name: "businessBoard",
        meta: {
          title: "经营看板",
        },
        hidden: false,
        component: () => import("@/views/dataBoard/businessBoard/index.vue"),
      },
      {
        // 运营看板
        path: "/opratingBoard",
        name: "opratingBoard",
        meta: {
          title: "运营看板",
        },
        hidden: false,
        component: () => import("@/views/dataBoard/opratingBoard/index.vue"),
      },
      {
        // 学生管理看板
        path: "/studentManageBoard",
        name: "studentManageBoard",
        meta: {
          title: "学生管理看板",
        },
        hidden: false,
        component: () =>
          import("@/views/dataBoard/studentManageBoard/index.vue"),
      },
      {
        // 招生看板
        path: "/admissionsBoard",
        name: "admissionsBoard",
        meta: {
          title: "招生看板",
        },
        hidden: false,
        component: () => import("@/views/dataBoard/admissionsBoard/index.vue"),
      },
      {
        // 人力看板
        path: "/manpowerBoard",
        name: "manpowerBoard",
        meta: {
          title: "人力看板",
        },
        hidden: false,
        component: () => import("@/views/dataBoard/manpowerBoard/index.vue"),
      },
      {
        // 分公司看板
        path: "/branchOffice",
        name: "branchOffice",
        meta: {
          title: "分公司看板",
        },
        hidden: false,
        component: () => import("@/views/dataBoard/branchOffice/index.vue"),
      },
    ],
  },
];
