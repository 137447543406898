import axios from "axios";
import errorCode from "@/utils/errorCode";
import { Message } from "element-ui";
import router from "@/router";
import { getToken, setToken, removeToken } from "@/utils/auth";
const http = axios.create({
  baseURL: "/webForm",
  timeout: 200000,
});

// 添加请求拦截器
http.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    // Authorization
    if (!config.headers["token"] && config.url != "/mng/loginuser/login") {
      config.headers["token"] = getToken();
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
http.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    if (response.data.code && response.data.code === '1') {
      console.log(response.data);
      // Message.error(response.data.msg)
    } else if (response.data.code === '2') {
      sessionStorage.removeItem("userInfo");
      localStorage.removeItem('changePost')
      removeToken("x-access-token");
      router.push("/login");
    } else {
      if (response.headers.token) {
        let isAutoLogin = sessionStorage.getItem("x-access-token");
        let isNoAutoLogin = sessionStorage.getItem("x-access-token");

        if (isAutoLogin && !isNoAutoLogin) {
          setToken(response.headers.token, true);
        } else if (!isAutoLogin && isNoAutoLogin) {
          setToken(response.headers.token, false);
        }
      }
    }
    return response;
  },
  function (error) {
    // 对响应错误做点什么
    const message =
      error.response.data.msg ||
      errorCode[error.response.status] ||
      errorCode["default"];

    if (error.response.status === 401) {
      // Message.error(message);
      // localStorage.removeItem("x-access-token");
      router.push("/login");
      return Promise.reject(error);
    } else {
      Message.error(message);
      return Promise.reject(error);
    }
  }
);

export default http;
