/*
    系统设置路由
 */
export default [
  {
    path: "/morbidity",
    meta: {
      title: "发病/患病率",
    },
    component: () => import("@/layout/Main"),
    hidden: false,
    children: [
      {
        // 发病/患病率总部
        path: "/morbidtyList",
        name: "morbidtyList",
        meta: {
          title: "发病/患病率/总部",
          keepAlive:true,
        },
        hidden: false,
        component: () => import("@/views/morbidity/morbidtyList/index.vue"),
      },
      {
        // 发病/患病率分公司
        path: "/mobidtyCompany",
        name: "mobidtyCompany",
        meta: {
          title: "发病/患病率/分公司",
          keepAlive:true,
        },
        hidden: false,
        component: () =>
          import("@/views/morbidity/morbidtyList/mobidtyCompany.vue"),
      },
      {
        // 发病/患病率园校
        path: "/mobidtySchool",
        name: "mobidtySchool",
        meta: {
          title: "发病/患病率/园校",
          keepAlive:true,
        },
        hidden: false,
        component: () =>
          import("@/views/morbidity/morbidtyList/mobidtySchool.vue"),
      },
      {
        // 生成图表
        path: "/bringcharts",
        name: "bringcharts",
        meta: {
          title: "查看图表",
        },
        hidden: true,
        component: () => import("@/views/morbidity/bringcharts/index.vue"),
      },
      {
        // 发病/患病率查看
        path: "/mobidtyLoook",
        name: "mobidtyLoook",
        meta: {
          title: "发病/患病率-查看",
        },
        hidden: true,
        component: () => import("@/views/morbidity/mobidtyLoook/index.vue"),
      },
      {
        // 新增患病
        path: "/morbidtyAdded",
        name: "morbidtyAdded",
        meta: {
          title: "发病/患病率-新增",
        },
        hidden: true,
        component: () => import("@/views/morbidity/morbidtyAdded/report.vue"),
      },
    ],
  },
];
