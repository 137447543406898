import http from "../http.interceptors";
const dataBoardApi = {
    /**
     * 招生看板
     */
    // 客户关注点
    getClientFocus: (params) => http.get("/report/enroll/clientFocus", { params }),
    // 客户转化率
    getClientRate: (params) => http.get("/report/enroll/clientRate", { params }),
    // 招生完成率
    getEnrollComplete: (params) => http.get("/report/enroll/enrollComplete", { params }),
    // 招生问题
    getEnrollQuestion: (params) => http.get("/report/enroll/enrollQuestion", { params }),
    // 在校生完成率
    getInternalComplete: (params) => http.get("/report/enroll/internalComplete", { params }),
    // 定金转化率
    getOrderRate: (params) => http.get("/report/enroll/orderRate", { params }),
    // 来源渠道
    getSources: (params) => http.get("/report/enroll/sources", { params }),
    // 意向储备不足
    getUnderReserve: (params) => http.get("/report/enroll/underReserve", { params }),
    /*
        人力看板
    */ 
    // 年龄结构
    getAgeDistribute: (params) => http.get("/report/human/ageDistribute", { params }),
    // 总员工数
    getAllStaff: (params) => http.get("/report/human/allStaff", { params }),
    // 持证率
    getCredentialsRate: (params) => http.get("/report/human/credentialsRate", { params }),
    // 学历分布
    getEduDistribute: (params) => http.get("/report/human/eduDistribute", { params }),
    // 编制执行率
    getExecuteRate: (params) => http.get("/report/human/executeRate", { params }),
    // 人力发展
    getHumanDevelop: (params) => http.get("/report/human/humanDevelop", { params }),
    // 人力成本执行率
    getHumanExecuteRate: (params) => http.get("/report/human/humanExecuteRate", { params }),
    // 员工流失率
    getStaffLeaveRate: (params) => http.get("/report/human/staffLeaveRate", { params }),
    // 员生比
    getStudentRate: (params) => http.get("/report/human/studentRate", { params }),
    // 师生比
    getTeacherRate: (params) => http.get("/report/human/teacherRate", { params }),
    /**
     * 经营看板
     */
    // 招生完成率
    getEnrollRate: (params) => http.get("/report/manage/enrollRate", { params }),
    // 支出预算执行率
    getExpendRate: (params) => http.get("/report/manage/expendRate", { params }),
    // 收入分布
    getIncomeDistribute: (params) => http.get("/report/manage/incomeDistribute", { params }),
    // 收入执行率
    getIncomeRate: (params) => http.get("/report/manage/incomeRate", { params }),
    // 生均利润
    getProfit: (params) => http.get("/report/manage/profit", { params }),
    // 利润执行率
    getProfitRate: (params) => http.get("/report/manage/profitRate", { params }),
    // 伙食费执行率
    getWagesExecute: (params) => http.get("/report/manage/wagesExecute", { params }),
}
export { dataBoardApi };