/*
    系统设置路由
 */
export default [
  {
    path: "/opratingManage",
    meta: {
      title: "运营管理",
    },
    component: () => import("@/layout/Main"),
    hidden: false,
    children: [
      {
        // 运营项目
        path: "/operationalItems",
        name: "operationalItems",
        meta: {
          title: "运营项目",
          keepAlive:true
        },
        hidden: false,
        component: () =>
          import("@/views/opratingManage/operationalItems/index.vue"),
      },
      {
        // 添加，编辑，详情
        path: "/editOperationalItems",
        name: "editOperationalItems",
        meta: {
          title: "新增项目",
        },
        hidden: true,
        component: () =>
          import("@/views/opratingManage/operationalItems/edit.vue"),
      },
      {
        // 运营模板
        path: "/operatingTemplate",
        name: "operatingTemplate",
        meta: {
          title: "运营模板",
          keepAlive:true
        },
        hidden: false,
        component: () =>
        // E:\project\1K_JYDD\07_code\jy-supervision\src\views\opratingManage\operatingTemplate
          import("@/views/opratingManage/operatingTemplate/index.vue"),
      },
      {
        // 运营模板，添加，编辑
        path: "/editOperatingTemplate",
        name: "editOperatingTemplate",
        meta: {
          title: "编辑模板",
        },
        hidden: true,
        component: () =>
          import("@/views/opratingManage/operatingTemplate/edit.vue"),
      },
      {
        // 运营模板,详情
        path: "/detailOperatingTemplate",
        name: "detailOperatingTemplate",
        meta: {
          title: "模板详情",
        },
        hidden: true,
        component: () =>
          import("@/views/opratingManage/operatingTemplate/detail.vue"),
      },
      {
        // 运营任务
        path: "/operatingTask",
        name: "operatingTask",
        meta: {
          title: "运营任务发布",
          keepAlive:true
        },
        hidden: false,
        component: () =>
          import("@/views/opratingManage/operatingTask/index.vue"),
      },
      {
        // 运营任务
        path: "/editOperatingTask",
        name: "editOperatingTask",
        meta: {
          title: "编辑运营任务",
        },
        hidden: true,
        component: () =>
          import("@/views/opratingManage/operatingTask/edit.vue"),
      },
      {
        // 待办任务
        path: "/upcomingTasksHead",
        name: "upcomingTasksHead",
        meta: {
          title: "任务列表/总部",
          keepAlive:true
        },
        hidden: false,
        component: () =>
          import("@/views/opratingManage/upcomingTasks/index.vue"),
      },
      {
        // 待办任务
        path: "/upcomingTasksBranchOffice",
        name: "upcomingTasksBranchOffice",
        meta: {
          title: "任务列表/分公司",
          keepAlive:true
        },
        hidden: false,
        component: () =>
          import("@/views/opratingManage/upcomingTasks/index.vue"),
      },
      {
        // 待办任务
        path: "/upcomingTasksPrincipal",
        name: "upcomingTasksPrincipal",
        meta: {
          title: "任务列表/园校",
          keepAlive:true
        },
        hidden: false,
        component: () =>
          import("@/views/opratingManage/upcomingTasks/index.vue"),
      },
      {
        // 待办任务详情
        path: "/upcomingTasksDetail",
        name: "upcomingTasksDetail",
        meta: {
          title: "待办任务详情",
        },
        hidden: false,
        component: () =>
          import("@/views/opratingManage/upcomingTasks/detail.vue"),
      },
      {
        // 待办任务-逾期反馈
        path: "/upcomingTasksFeedback",
        name: "upcomingTasksFeedback",
        meta: {
          title: "逾期反馈",
        },
        hidden: false,
        component: () =>
          import("@/views/opratingManage/upcomingTasks/feedback.vue"),
      },
      {
        // 待办任务-审批
        path: "/upcomingTasksApprove",
        name: "upcomingTasksApprove",
        meta: {
          title: "审批",
        },
        hidden: false,
        component: () =>
          import("@/views/opratingManage/upcomingTasks/approve.vue"),
      },
      {
        // 待办任务-办理
        path: "/upcomingTasksHandle",
        name: "upcomingTasksHandle",
        meta: {
          title: "办理",
        },
        hidden: false,
        component: () =>
          import("@/views/opratingManage/upcomingTasks/handle.vue"),
      },
      // {
      //   // 待办任务
      //   path: "/upcomingTasksExecutor",
      //   name: "upcomingTasksExecutor",
      //   meta: {
      //     title: "待办任务-院校任务执行人",
      //   },
      //   hidden: false,
      //   component: () =>
      //     import("@/views/opratingManage/upcomingTasks/index.vue"),
      // },
      // {
      //   // 待办任务
      //   path: "/editOperatingTask",
      //   name: "editOperatingTask",
      //   meta: {
      //     title: "编辑运营任务",
      //   },
      //   hidden: false,
      //   component: () =>
      //     import("@/views/opratingManage/upcomingTasks/edit.vue"),
      // },
      // {
      //   // 任务执行情况
      //   path: "/operatingCondition",
      //   name: "operatingCondition",
      //   meta: {
      //     title: "任务执行情况",
      //   },
      //   hidden: false,
      //   component: () =>
      //     import("@/views/opratingManage/operatingCondition/index.vue"),
      // },
      // {
      //   // 逾期反馈
      //   path: "/overdueFeedback",
      //   name: "overdueFeedback",
      //   meta: {
      //     title: "逾期反馈",
      //   },
      //   hidden: false,
      //   component: () =>
      //     import("@/views/opratingManage/overdueFeedback/index.vue"),
      // },
    ],
  },
];
