import http from "../http.interceptors";
const targetApi = {
    //获取目标列表
    getTargetList: (params) => http.get("/target/info/page", { params }),
    //保存新园开办
    savePark: (params) => http.post('/target/new-garden/save', params),
    //获取目标进度信息
    getTargetProgressInfo: (params) => http.post('/target/info/tree?id=' + params.id),
    //获取目标信息
    getTargetInfo: (params) => http.get('/target/info/getById/' + params.id),
    //新增目标
    saveTarget: (params) => http.post("/target/info/save", params),
    //编辑目标-岗位人
    updataDutyUser: (params) => http.post("/target/info/update", params),
    //删除目标
    // removeTarget: (params) => http.post("/target/info/remove/"+params.id),
    //修改目标进度
    updataTarget: (params) => http.post("/target/info/updataTargetById",params),
    // 督办记录列表
    supervisionRecordList: (params) => http.get('/target/supervision-record/list?detailId=' + params),
    //保存督办
    saveSupervisionRecord: (params) => http.post("/target/supervision-record/save", params ),
    // 批量督办
    saveSupervisionRecordBatchSave: (params) => http.post("/target/supervision-record/batchSave", params ),
    // 新园开办进度列表
    // newGardenList: (params) => http.get('/target/new-garden/page?detailId=' + params),
    newGardenList: (params) => http.get('/target/new-garden/page' , {params}),
    // 新园开办进度添加
    // 新园开办进度详情
    newGardenDetail: (params) => http.get("/target/new-garden/getById/"+params ),
    // 修改新园开办进度
    newGardenUpdata: (params) => http.post("/target/new-garden/updateById", params ),
    // 新园开办进度删除
    newGardenRemove: (params) => http.post("/target/new-garden/remove/"+params ),
    // 园所拓展进度列表
    expandGardenList: (params) => http.get('/target/expand-garden/page' , {params}),
    // 园所拓展进度添加
    expandGardenSave: (params) => http.post("/target/expand-garden/save", params ),
    // 园所拓展进度详情
    expandGardenDetail: (params) => http.get("/target/expand-garden/getById/"+params ),
    // 园所拓展进度删除
    expandGardenRemove: (params) => http.post("/target/expand-garden/remove/"+params ),
    // 修改园所拓展进度
    expandGardenUpdata: (params) => http.post("/target/expand-garden/updateById", params ),
    // dict/saveTargetDict  自定义目标类型
    saveTargetDict:(params) => http.post("/mng/dict/saveTargetDict", params ),
    // 责任部门
    getDepartList:(params) => http.get("/mng/dept/getDeptInHead", params ),
    // 分解对象列表
    getDecomposeObjectList:(params) => http.get("/mng/dept/getSchoolForTree", params ),
    // 查询员工树——目标分解
    getUserPostForTree:(params) => http.get("/mng/post/getUserPostForTree", {params} ),
    // 行政区划
    region:(params) => http.get("/target/new-garden/region", {params} ),
}
export { targetApi };