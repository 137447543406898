import http from "../http.interceptors";

const insuranceApi = {
  /* 保险 */

  // 保险-查询投保信息
  getInsuranceInfo: (params) =>
    http.get("/mng/insurance/info/page", { params }),

  // 保险-查询未投保预警
  getUninsuredInfo: (params) =>
    http.get("/mng/insurance/info/uninsured", { params }),

  // 保险-同步未投保预警信息
  getUpBathIdsInfo: (params) =>
    http.post(
      "/mng/insurance/info/upBathIds?afterIds=" +
        params.afterIds +
        "&beforeIds=" +
        params.beforeIds
    ),

  // 保险-出险信息查询
  getPayPage: (params) => http.get("/mng/insurance/pay/page", { params }),

  //园校投保率
  getInfoRate: (params) => http.get("/mng/insurance/info/rate", { params }),

  //查询查看保单
  getAttachmentPage: (params) =>
    http.get("/mng/insurance/attachment/page", { params }),

  //总部删除
  getPayRemove: (params) => http.post("mng/insurance/pay/remove/" + params.id),

  ///出险查看
  getPayGetById: (params) => http.get("mng/insurance/pay/getById/" + params.id),

  //园校新增
  getPaySave: (params) => http.post("mng/insurance/pay/save", params),

  //更新
  getPayUpdate: (params) => http.post("mng/insurance/pay/updateById", params),

  //幼儿出险生成图表
  getChildLink: (params) =>
    http.get("mng/insurance/pay/getChildLink", { params }),

  //上传文件保存
  getAttachmentSave: (params) =>
    http.post("mng/insurance/attachment/save", params),
};

export { insuranceApi };
