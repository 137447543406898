/*
    系统设置路由
 */
export default [
  {
    path: "/systemSet",
    meta: {
      title: "系统设置",
    },
    component: () => import("@/layout/Main"),
    hidden: false,
    children: [
      {
        // 提醒设置
        path: "/remindSet",
        name: "remindSet",
        meta: {
          title: "提醒设置",
        },
        hidden: false,
        component: () => import("@/views/systemSet/remindSet.vue"),
      },
      {
        // 提醒设置
        path: "/manualSync",
        name: "manualSync",
        meta: {
          title: "手动同步",
        },
        hidden: false,
        component: () => import("@/views/systemSet/manualSync.vue"),
      },
    ],
  },
];
