/*
    系统设置路由
 */
export default [
  {
    path: "/targetManage",
    meta: {
      title: "目标管理",
    },
    component: () => import("@/layout/Main"),
    hidden: false,
    children: [
      {
        // 目标列表
        path: "/targetListHeadquarters",
        name: "targetListHeadquarters",
        meta: {
          title: "目标列表/总部",
          keepAlive:true,
        },
        hidden: false,
        component: () => import("@/views/targetManage/targetList/index.vue"),
      },
      {
        // 目标详情
        path: "/targetDetail",
        name: "targetDetail",
        meta: {
          title: "目标详情",
        },
        hidden: true,
        component: () => import("@/views/targetManage/targetList/targetDetail.vue"),
      },
      {
        // 目标列表-分公司
        path: "/targetListCompany",
        name: "targetListCompany",
        meta: {
          title: "目标列表/分公司",
          keepAlive:true,
        },
        hidden: false,
        component: () => import("@/views/targetManage/targetList/companyManger.vue"),
      },
      {
        // 目标列表-园校
        path: "/targetListParkschool",
        name: "targetListParkschool",
        meta: {
          title: "目标列表/园校",
          keepAlive:true,
        },
        hidden: false,
        component: () => import("@/views/targetManage/targetList/parkschool.vue"),
      },
      {
        // 创建目标
        path: "/createTarget",
        name: "createTarget",
        meta: {
          title: "新增目标",
        },
        hidden: true,
        component: () => import("@/views/targetManage/createTarget/index.vue"),
      },
      {
        // 更新进度
        path: "/uploadProgress",
        name: "uploadProgress",
        meta: {
          title: "更新进度",
        },
        hidden: true,
        component: () => import("@/views/targetManage/uploadProgress/index.vue"),
      },
      {
        // 新园开办-新增/编辑
        path: "/registrationPark",
        name: "registrationPark",
        meta: {
          title: "新增/编辑",
        },
        hidden: true,
        component: () => import("@/views/targetManage/registrationPark/index.vue"),
      },
      {
        // 园所拓展
        path: "/parkExtend",
        name: "parkExtend",
        meta: {
          title: "园所拓展",
        },
        hidden: true,
        component: () => import("@/views/targetManage/parkExtend/index.vue"),
      },
      {
        // 查看进度
        path: "/showProgress",
        name: "showProgress",
        meta: {
          title: "查看进度",
        },
        hidden: true,
        component: () => import("@/views/targetManage/showProgress/index.vue"),
      }
    ],
  },
];
