/*
    出勤路由
 */
export default [
  {
    path: "/attendance",
    meta: {
      title: "出勤",
    },
    component: () => import("@/layout/Main"),
    hidden: false,
    children: [
      {
        // 出勤总公司
        path: "/attendanceIndex",
        name: "attendanceIndex",
        meta: {
          title: "出勤/总公司",
          keepAlive:true,
        },
        hidden: false,
        component: () => import("@/views/attendance/attendanceIndex.vue"),
      },
      {
        // 出勤分公司
        path: "/attendanceCompany",
        name: "attendanceCompany",
        meta: {
          title: "出勤/分公司",
          keepAlive:true,
        },
        hidden: false,
        component: () => import("@/views/attendance/attendanceCompany.vue"),
      },
      {
        // 出勤园校
        path: "/attendanceSchool",
        name: "attendanceSchool",
        meta: {
          title: "出勤/园校",
          keepAlive:true,
        },
        hidden: false,
        component: () => import("@/views/attendance/attendanceSchool.vue"),
      },
    ],
  },
];
