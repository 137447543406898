<template>
  <div class="login-page">
    <div class="login-wrap" :style="loginWarp">
      <div class="img-wrap">
        <img src="../../assets/img/leftLogin.png" alt="" />
      </div>

      <div class="login-form-wrap">
        <div
          class="forgetPasswordBack"
          v-if="forgetPasswordStatus == true"
          @click="handleGoBackLogin"
        >
          <i class="el-icon-back forgetPasswordBack-icon"></i>
          <span class="forgetPasswordBack-title">重置密码</span>
        </div>
        <div class="login-form-center">
          <div class="login-title">
            <div class="login-title-text">
              <img src="../../assets/img/login-icon.png" alt="" />
              运营管理平台
            </div>
          </div>

          <div class="login-form" v-if="forgetPasswordStatus == true">
            <div class="phone">
              <span class="phone-title">手机号</span>
			  <!-- @change="handleResetPasswordFormPhone" -->
              <input
                type="text"
                oninput="value=value.replace(/[^\d]/g,'')"
                class="login-input"
                placeholder="请输入您的手机号"
                v-model="resetPasswordForm.phone"
                maxlength="11"
              />
            </div>
            <div class="passWord">
              <span class="passWord-title">密码</span>
              <input
                class="login-input"
                type="password"
                placeholder="请输入您的密码"
                maxlength="20"
                v-model="resetPasswordForm.password"
                @change="handleValidatePass"
              />
            </div>
            <div class="passWord">
              <span class="passWord-title">确认密码</span>
              <input
                class="login-input"
                type="password"
                placeholder="请再次输入新的登录密码"
                maxlength="20"
                v-model="resetPasswordForm.confirmPassword"
                @change="handleValidateTwoPass"
              />
            </div>
            <div class="auth-code">
              <span class="passWord-title">验证码</span>
              <div class="login-auth-input">
                <input
                  type="text"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  class="login-input auth-code-input"
                  placeholder="请输入短信验证码"
                  v-model="resetPasswordForm.textCode"
                  maxlength="6"
                  @change="handleResetTextCode"
                />
                <div
                  slot="suffix"
                  class="auth-button"
                  :class="{ gray: isClick }"
                  @click.stop="handleResetPhoneTextCode"
                >
                  {{ !isClick ? "获取验证码" : secondNum }}
                </div>
              </div>
            </div>
            <div class="errMessageTips">
              {{ errMessageTips }}
            </div>
            <button
              class="login-btn"
              v-loading="buttonLoading"
              @click="handleResetPassword"
            >
              确定
            </button>
            <!-- <div slot="footer" class="dialog-footer">
              <el-button :loading="dialogButtonloading" @click="handleClose"
                >取 消</el-button
              >
              <el-button
                :loading="dialogButtonloading"
                type="primary"
                @click="handleSubmit"
                >重 置</el-button
              >
            </div> -->
          </div>

          <div class="login-con-wall" v-else>
            <div class="login-style">
              <div
                v-for="(item, index) in loginStyleList"
                :key="index"
                :class="
                  activeIndex == index
                    ? 'login-style-tab login-style-tab-active'
                    : 'login-style-tab'
                "
                @click="handleLoginStyle(item, index)"
              >
                <span>{{ item }}</span>
                <div
                  :class="
                    activeIndex == index
                      ? 'login-style-border login-style-border-active'
                      : 'login-style-border'
                  "
                ></div>
              </div>
            </div>
            <div v-if="activeIndex == '0'" class="login-form">
              <div class="phone">
                <span class="phone-title">手机号</span>
                <input
                  type="text"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  class="login-input"
                  placeholder="请输入您的手机号"
                  maxlength="11"
                  v-model="loginForm.username"  
                />
              </div>
              <div class="passWord">
                <span class="passWord-title">密码</span>
                <input
                  class="login-input"
                  type="password"
                  placeholder="请输入您的密码"
                  maxlength="20"
                  v-model="loginForm.password"
                  @change="handleInputPassword"
                />
              </div>
              <span class="passWord-title">验证码</span>
              <div class="login-code">
                <label>
                  <input
                    type="text"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    class="login-input login-validate"
                    placeholder="请输入验证码"
                    maxlength="4"
                    v-model="loginForm.imgCode"
                    @change="handleInputCode"
                  />
                </label>
                <div class="login-code-img">
                  <img
                    v-if="code.src"
                    :src="code.src"
                    alt=""
                    @click="refreshCode"
                  />
                </div>
              </div>
              <div class="errMessageTips">
                {{ errMessageTips }}
              </div>
              <div>
                <button
                  v-if="activeIndex == '0'"
                  class="login-btn"
                  v-loading="buttonLoading"
                  @click="handleLogin(1)"
                >
                  登录
                </button>
                <!-- <button
                  v-if="activeIndex == '1'"
                  class="login-btn"
                  v-loading="buttonLoading"
                  @click="handleLoginTextCode(2)"
                >
                  登录
                </button> -->
              </div>
              <div class="auto-pass">
                <p class="pass-forget" @click="handleForgetPassword">
                  忘记密码?
                </p>
              </div>
            </div>
            <div v-if="activeIndex == '1'" class="login-form">
              <div class="phone">
                <span class="phone-title">手机号</span>
                <input
                  type="text"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  class="login-input"
                  placeholder="请输入您的手机号"
                  maxlength="11"
                  v-model="loginForm.username"
                />
              </div>
              <div class="auth-code">
                <span class="auth-code-title">验证码</span>

                <div class="login-auth-input">
                  <input
                    type="text"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    class="login-input auth-code-input"
                    placeholder="请输入短信验证码"
                    maxlength="6"
                    v-model="loginForm.textCode"
                  />
                  <div
                    slot="suffix"
                    class="auth-button"
                    :class="{ gray: isClick }"
                    @click.stop="handleAuthCode"
                  >
                    {{ !isClick ? "获取验证码" : secondNum }}
                  </div>
                </div>
              </div>
              <div class="errMessageTips">
                {{ errMessageTips }}
              </div>
              <div>
                <!-- <button
                  v-if="activeIndex == '0'"
                  class="login-btn"
                  v-loading="buttonLoading"
                  @click="handleLogin(1)"
                >
                  登录
                </button> -->
                <button
                  v-if="activeIndex == '1'"
                  class="login-btn"
                  v-loading="buttonLoading"
                  @click="handleLoginTextCode(2)"
                >
                  登录
                </button>
              </div>
              <div class="auto-pass">
                <p class="pass-forget" @click="handleForgetPassword">
                  忘记密码?
                </p>
              </div>
              <span style="visibility: hidden" class="passWord-title"
                >验证码</span
              >
              <div class="login-code" style="visibility: hidden">
                <label>
                  <input
                    type="text"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    class="login-input login-validate"
                    placeholder="请输入验证码"
                    maxlength="4"
                    v-model="loginForm.imgCode"
                    @change="handleInputCode"
                  />
                </label>
                <div class="login-code-img">
                  <img
                    v-if="code.src"
                    :src="code.src"
                    alt=""
                    @click="refreshCode"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="successDialogVisible"
      :append-to-body="true"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :show-close="false"
      :close-on-press-escape="false"
      @before-close="handleClose"
      @close="handleClose"
      width="50%"
    >
      <div class="success-body">
        <img
          class="success-icon"
          src="../../assets/img/success-dialog-icon.png"
          alt=""
        />
        <div class="success-title">密码重置成功</div>
        <div class="success-remark">请妥善保管您的账户信息</div>
        <el-button
          class="success-button"
          type="primary"
          :loading="dialogButtonloading"
          @click="handleSuccessLogin('click')"
          >进入系统({{ submitNum }})</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import randomLenNum from "@/utils/randomLenNum";
// import encryption from "@/utils/encryption";
import qs from "qs";
import { setToken } from "@/utils/auth";
import asyncRouter from "@/router/asyncRouter";
import initialData from "@/utils/initialData";
import watermark from "@/utils/watermark";
import { validNoContinue, validNoSame } from "@/utils/validate";

export default {
  data() {
    return {
      loginWarp: {
        backgroundColor: "#fff",
      },
      code: {
        src: "",
        // len: 4,
        // type: "image",
        key: "",
      },

      loginForm: {
        username: "",
        password: "",
        imgCode: "",
        textCode: "",
        key: "",
      },
      buttonLoading: false, //按钮loading
      dialogButtonloading: false,
      resetPasswordForm: {
        phone: "",
        password: "",
        confirmPassword: "",
        textCode: "",
      }, //重置密码弹窗表单

      isClick: false, //是否点击获取验证码按钮
      secondNum: 60, //秒数
      setIntervalObject: null, //计时对象
      submitNum: 5, //进入系统倒计时
      setIntervalResetObject: null, //密码重置成功计时对象
      successDialogVisible: false, //密码重置成功
      successForm: {
        username: "",
        password: "",
      },
      loginStyleList: ["密码登录", "短信登录"], //登录方式
      activeIndex: "0", //选中登录方式索引
      forgetPasswordStatus: false, //忘记密码/登录显示状态
      errMessageTips: "", //错误提示语
      valiTextCodeStatus: false,
    };
  },

  created() {
    let _this = this;
    document.onkeydown = function (e) {
      if (_this.$route.path.indexOf("/login") >= 0) {
        if (e.keyCode == 13) {
          if (_this.loginForm.imgCode !== "") {
            _this.handleLogin(1); //自己写的登录方法，点击事件
          } else if (_this.loginForm.textCode !== "") {
            _this.handleLoginTextCode(2);
          } else if (_this.resetPasswordForm.textCode !== "") {
            _this.handleResetPassword();
          }
        }
      }
    };
  },

  mounted() {
    this.refreshCode();
  },
  methods: {
    beforeDestroy() {
      document.onkeydown = null;
      this.isClick = false;
      this.secondNum = 60;
      if (this.setIntervalObject !== null) {
        clearInterval(this.setIntervalObject);
        this.setIntervalObject = null;
      }
    },

    //获取图形验证码
    refreshCode() {
      this.loginForm.imgCode = "";
      this.$api.getAuthCode({}).then((res) => {
        if (res.data.code == 0) {
          this.code.src = res.data.data.image;
          this.code.key = res.data.data.key;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //登录方式切换
    handleLoginStyle(item, index) {
      this.activeIndex = index;
      this.loginForm = {
        username: "",
        password: "",
        imgCode: "",
        textCode: "",
        key: "",
      };

      this.resetPasswordForm = {
        phone: "",
        password: "",
        confirmPassword: "",
        TextCode: "",
      };

      this.errMessageTips = "";
      this.isClick = false;
      this.secondNum = 60;
      if (this.setIntervalObject !== null) {
        clearInterval(this.setIntervalObject);
        this.setIntervalObject = null;
      }
    },

    //忘记密码
    handleForgetPassword() {
      this.forgetPasswordStatus = true;
      this.loginForm = {
        username: "",
        password: "",
        imgCode: "",
        textCode: "",
        key: "",
      };

      this.resetPasswordForm = {
        phone: "",
        password: "",
        confirmPassword: "",
        TextCode: "",
      };
      this.errMessageTips = "";
      this.isClick = false;
      this.secondNum = 60;
      if (this.setIntervalObject !== null) {
        clearInterval(this.setIntervalObject);
        this.setIntervalObject = null;
      }
    },

    //返回登录页面
    handleGoBackLogin() {
      this.forgetPasswordStatus = false;
      this.loginForm = {
        username: "",
        password: "",
        imgCode: "",
        textCode: "",
        key: "",
      };

      this.resetPasswordForm = {
        phone: "",
        password: "",
        confirmPassword: "",
        TextCode: "",
      };
      this.errMessageTips = "";
    },

    //手机号校验
    handleInputPhone() {
      let myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
      // /^1[2-9]\d{9}$/
      if (!myreg.test(this.loginForm.username)) {
        // this.loginForm.username = "";
        this.errMessageTips = "手机号格式不正确";
      } else {
        this.errMessageTips = "";
      }
    },

    //重置密码手机号校验
    handleResetPasswordFormPhone() {
      let myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
      // /^1[2-9]\d{9}$/
      if (!myreg.test(this.resetPasswordForm.phone)) {
        // this.loginForm.username = "";
        this.errMessageTips = "手机号格式不正确";
      } else {
        this.errMessageTips = "";
      }
    },

    //密码校验
    handleInputPassword() {
      if (this.loginForm.password.length < 6) {
        this.errMessageTips = "密码长度最小为6位";
      } else {
        this.errMessageTips = "";
      }
    },

    //验证码
    handleInputCode() {
      if (this.loginForm.imgCode !== "") {
        this.errMessageTips = "";
        return false;
      }
    },

    //账号密码登录校验
    handleLogin(type) {
      const loginForm = this.loginForm;

      if (!loginForm.username) {
        this.errMessageTips = "请输入手机号";
        return false;
      }

      if (!loginForm.password) {
        this.errMessageTips = "请输入密码";
        return false;
      }

      if (loginForm.password.length < 6) {
        this.errMessageTips = "密码长度最小为6位";
        return false;
      }

      if (!loginForm.imgCode) {
        this.errMessageTips = "请输入图片验证码";
        return false;
      }

      this.loginByUsername(type, (data) => {
        if (!data) {
          return;
        }
        this.$router.push("/workCalendarIndex");
      });
    },

    //手机验证码登录校验
    handleLoginTextCode(type) {
      const loginForm = this.loginForm;

      if (!loginForm.username) {
        this.errMessageTips = "请输入手机号";
        return false;
      }

      if (!loginForm.textCode) {
        this.errMessageTips = "请输入手机验证码";
        return false;
      }

      this.loginByUsername(type, (data) => {
        if (!data) {
          return;
        }
        this.$router.push("/workCalendarIndex");
      });
    },

    //登录接口
    loginByUsername(type, callback) {
      this.buttonLoading = true;
      let codeType = "";
      if (type == 1) {
        codeType = this.loginForm.imgCode;
      } else if (type == 2) {
        codeType = this.loginForm.textCode;
      }

      const params = {
        type: type, //1：账号密码 2：手机号验证码
        phone: this.loginForm.username,
        password: this.loginForm.password,
        key: this.code.key,
        code: codeType,
      };

      this.$api.loginByUsername(params).then((res) => {
        this.buttonLoading = false;
        if (res.data.code == 0) {
          this.$message.success("登录成功");
          setToken(res.data.data.token, this.autoPass);

          this.$store.commit("getUserInfo", res.data.data);
          sessionStorage.setItem("userInfo", JSON.stringify(res.data.data));
          let userInfo = res.data.data

            // asyncRouter();
            // initialData();
            // callback(true);
            // 没有缓存过
            if(!localStorage.getItem('changePost')){
              this.$api.getPostByUser().then(res=>{
                if(res.data.code == 0){
                  let tableData = this.platFn(res.data.data)
                  let row = tableData[0]
                  this.setPost(row,userInfo,callback)
                }
              })
            }else{
              // 缓存过 岗位
              let row = JSON.parse(localStorage.getItem('changePost'))
              if(row.userPhone === this.loginForm.username){
                this.setPost(row,userInfo,callback)
              }else{
                this.$api.getPostByUser().then(res=>{
                  if(res.data.code == 0){
                    let tableData = this.platFn(res.data.data)
                    let row = tableData[0]
                    this.setPost(row,userInfo,callback)
                  }
                })
              }
            }
        } else if(res.data.code == 4) {
          this.refreshCode();
          callback && callback(false);
          this.$message.error(res.data.msg);
        }else{
          callback && callback(false);
          this.$message.error(res.data.msg);
        }
      });
    },
    platFn(list) {
      let res = []
      list.map(item =>{
        if(item.userPostInfo instanceof Array && item.userPostInfo.length > 0){
          res = res.concat(...item.userPostInfo)
        }
      })
      return res
    },
    setPost(row,userInfo,callback){
      this.$api.changePost(row).then(res=>{
        if(res.data.code == 0){
          let usersInfo = res.data.data
          this.$api.getMenu().then((res) => {
            this.$store.commit("getPermissions", res.data.data);
            
            // userInfo.schoolList = [
            //   {companyLabel: row.companyLabel?row.companyLabel:'-1',
            //   companyName: row.companyName?row.companyName:'',  
            //   schoolLabel: row.schoolLabel?row.schoolLabel:'-1',
            //   schoolName: row.schoolName?row.schoolName:'',}
            // ],
            // userInfo.officeLabelList = row.deptLabel
            // userInfo.postLabelList = row.postLabel
            sessionStorage.setItem('userInfo',JSON.stringify(usersInfo));
            this.$store.commit("getUserInfo", (usersInfo));
            row.userPhone = userInfo.userPhone
            localStorage.setItem('changePost',JSON.stringify(row))
            asyncRouter();
            initialData();
            callback(true);
          });
        }
      })

    },
    //获取验证码
    handleValidateTextCode(phoneNumber, smsType) {
      if (!this.isClick && this.valiTextCodeStatus) {
        this.$api
          .getVerificationCode({ phoneNumber: phoneNumber, smsType: smsType })
          .then((res) => {
            if (res.data.code == 0) {
              this.code.key = res.data.data.key;
              this.$message.success("验证码发送成功");

              this.isClick = true;
              this.setIntervalObject = setInterval(() => {
                if (this.secondNum == 0) {
                  this.isClick = false;
                  this.$nextTick(() => {
                    this.secondNum = 60;
                    clearInterval(this.setIntervalObject);
                    this.setIntervalObject = null;
                  });
                } else {
                  this.secondNum--;
                }
              }, 1000);
            } else {
              this.$message.error(res.data.msg);
            }
          });
      }
    },

    //手机号登录获取验证码
    handleAuthCode() {
      this.loginForm.textCode = "";
      if (this.loginForm.username !== "") {
        this.valiTextCodeStatus = /^[1][3,4,5,7,8][0-9]{9}$/.test(
          this.loginForm.username
        );
      } else {
        this.errMessageTips = "手机号不能为空！";
      }

      this.handleValidateTextCode(this.loginForm.username, "1");
    },

    //重置密码获取验证码
    handleResetPhoneTextCode() {
      this.resetPasswordForm.textCode = "";
      if (this.resetPasswordForm.phone !== "") {
        this.valiTextCodeStatus = /^[1][3,4,5,7,8][0-9]{9}$/.test(
          this.resetPasswordForm.phone
        );
      } else {
        this.errMessageTips = "手机号不能为空！";
      }
      this.handleValidateTextCode(this.resetPasswordForm.phone, "2");
    },

    //弹窗关闭
    handleClose() {
      this.resetPasswordVisible = false;
      this.$nextTick(() => {
        this.dialogButtonloading = false;
        this.resetPasswordForm = {
          phone: "",
          authCode: "",
          password: "",
          confirmPassword: "",
        };
        this.$refs.resetPasswordForm.clearValidate();
      });
    },

    //重置密码校验
    ValidateTwoPassword(val) {
      let rules1 = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{8,16}$/g;
      if (val == "") {
        this.errMessageTips = "请输入密码";
      } else {
        if (!rules1.test(val)) {
          this.errMessageTips = "登录密码为8-16位且必须有数字、字母";
        } else if (!validNoSame(val)) {
          this.errMessageTips = "登录密码不允许3个以上的重复字符";
        } else if (!validNoContinue(val)) {
          this.errMessageTips = "登录密码不允许3个以上的连续字符";
        } else {
          this.errMessageTips = "";
        }
        //  else {
        //   if (this.resetPasswordForm.confirmPassword !== "") {
        //     this.$refs.resetPasswordForm.validateField("confirmPassword");
        //   }
        // }
      }
    },

    //第一次输入密码
    handleValidatePass() {
      this.ValidateTwoPassword(this.resetPasswordForm.password);
    },

    //第二次输入密码
    handleValidateTwoPass() {
      if (
        this.resetPasswordForm.confirmPassword !==
        this.resetPasswordForm.password
      ) {
        this.errMessageTips = "两次输入密码不一致!";
      } else {
        this.errMessageTips = "";
      }
    },

    //重置密码验证码
    handleResetTextCode() {
      if (this.resetPasswordForm.textCode !== "") {
        this.errMessageTips = "";
      }
    },

    //重置密码密码校验
    handleResetPassword() {
      const resetPasswordForm = this.resetPasswordForm;

      if (!resetPasswordForm.phone) {
        this.errMessageTips = "请输入手机号";
        return false;
      }

      if (!resetPasswordForm.password) {
        this.errMessageTips = "请输入密码";
        return false;
      }

      if (!resetPasswordForm.confirmPassword) {
        this.errMessageTips = "请输入确认密码";
        return false;
      }

      if (resetPasswordForm.password !== resetPasswordForm.confirmPassword) {
        this.errMessageTips = "两次输入密码不一致";
        return false;
      }

      if (!resetPasswordForm.textCode) {
        this.errMessageTips = "请输入手机验证码";
        return false;
      }

      this.handleSubmitResetPassword();
    },

    //重置密码接口
    handleSubmitResetPassword() {
      this.dialogButtonloading = true;
      if (
        this.resetPasswordForm.password ==
        this.resetPasswordForm.confirmPassword
      ) {
        let params = {
          phoneNumber: this.resetPasswordForm.phone,
          code: this.resetPasswordForm.textCode,
          password: this.resetPasswordForm.password,
        };

        this.$api.resetPwd(params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success("密码重置成功");
            this.forgetPasswordStatus = false;
            // this.successForm = {
            //   username: this.resetPasswordForm.phone,
            //   password: this.resetPasswordForm.password,
            // };
            // this.loginForm = {
            //   username: this.resetPasswordForm.phone,
            //   password: "",
            //   code: "",
            //   key: "",
            // };
            this.refreshCode();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.errMessageTips = "请检查两次密码不一致或者验证码不正确！";
      }
    },

    //处理重置密码进入系统
    handleSuccessLogin(type) {
      if (type == "click") {
        this.dialogButtonloading = true;
        clearInterval(this.setIntervalResetObject);
        this.setIntervalResetObject = null;
        this.loginByUsername("reset", (data) => {
          if (!data) {
            return;
          }
          this.successDialogVisible = false;
          this.$nextTick(() => {
            this.dialogButtonloading = false;
            this.submitNum = 5;
            this.$router.push("/workCalendarIndex");
          });
        });
      } else {
        this.loginByUsername("reset", (data) => {
          if (!data) {
            return;
          }
          this.successDialogVisible = false;
          this.$nextTick(() => {
            this.dialogButtonloading = false;
            this.submitNum = 5;
            this.$router.push("/workCalendarIndex");
          });
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.login-page {
  width: 100%;
  height: 100%;
  display: flex;

  .login-con-wall {
    width: 100%;
  }

  .login-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .img-wrap {
      width: 67.25%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .login-form-wrap {
      width: 52.8%;
      height: 100%;
      // background-color: cornflowerblue;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .forgetPasswordBack {
        width: 86%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 4%;
      }

      .forgetPasswordBack-icon {
        font-weight: bold;
        font-size: 30px;
        cursor: pointer;
      }

      .forgetPasswordBack-title {
        font-weight: 400;
        font-size: 18px;
        margin-left: 7%;
      }

      .login-form-center {
        width: 64%;
        // width: 513px;
        // width: 67.75%;
        // margin: 0 15%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      .login-title {
        margin-bottom: 50px;
        // margin-bottom: 45px;

        .login-title-text {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 32px;
          color: #010334;
          font-weight: 400;
        }

        .login-title-text img {
          width: 40px;
          height: 40px;
          margin-right: 20px;
        }
      }

      .login-style {
        display: flex;
        align-items: center;
        // justify-content: center;
        cursor: pointer;
        margin-bottom: 50px;
        // margin-bottom: 40px;

        .login-style-tab {
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          color: #a8a6b9;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .login-style-tab:first-child {
          margin-right: 40px;
        }

        .login-style-tab-active {
          color: #3d3e4f;
        }

        .login-style-border {
          width: 20px;
          height: 3px;
          background: transparent;
          border-radius: 20px;
          margin-top: 9px;
        }

        .login-style-border-active {
          background: #003685;
        }
      }

      .login-form {
        width: 100%;

        .phone {
        }

        .phone-title,
        .passWord-title,
        .auth-code-title {
          display: inline-block;
          font-size: 18px;
          font-weight: 400;
          color: #010334;
          margin-bottom: 17px;
        }

        label {
          display: block;
          width: 65%;
        }

        input::placeholder {
          color: #d3d2dc;
        }

        .login-input {
          display: block;
          width: 96.5%;
          height: 45px;
          border-radius: 8px;
          outline: none;
          border: 1px solid #b5bdcf;
          padding-left: 2.4%;
          color: #010334;
          font-size: 18px;
          margin-bottom: 28px;
        }

        .login-user {
          background-image: url("../../assets/img/login-user.png");
        }

        .login-pass {
          background-image: url("../../assets/img/login-pass.png");
        }

        .login-code {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .login-code-img {
            height: 2.6049vw;
            img {
              height: 100%;
            }
          }
        }

        .auth-code {
          .login-auth-input {
            position: relative;
          }

          .auth-button {
            color: #3d3e4f;
            cursor: pointer;
            position: absolute;
            right: 4%;
            top: 34%;

            &.gray {
              color: #7d7d7d;
              cursor: none;
            }
          }
        }
      }

      .errMessageTips {
        font-size: 16px;
        font-weight: 400;
        color: #c6000b;
      }

      .login-btn {
        width: 100%;
        height: 55px;
        box-sizing: border-box;
        border: none;
        color: #fff;
        font-size: 1.1em;
        border-radius: 8px;
        margin-top: 40px;
        outline: none;
        cursor: pointer;
        background: #003685;
        box-shadow: 2px 8px 9px rgba(138, 138, 138, 0.12);
      }

      .auto-pass {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .pass-forget {
          cursor: pointer;
          color: #003685;
          font-size: 0.9em;
          line-height: 1;
        }
      }
    }
  }
}

.success-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .success-icon {
    width: 120px;
    height: 120px;
    margin-bottom: 30px;
  }
  .success-title {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    margin-bottom: 11px;
  }
  .success-remark {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 42px;
  }
}
</style>
