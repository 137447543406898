/*
    系统设置路由
 */
export default [
  {
    path: "/database",
    meta: {
      title: "资料库",
    },
    component: () => import("@/layout/Main"),
    hidden: false,
    children: [
      {
        // 目录管理
        path: "/catalogManage",
        name: "catalogManage",
        meta: {
          title: "目录管理",
        },
        hidden: false,
        component: () => import("@/views/database/catalogManage/index.vue"),
      },
      {
        // 目录管理
        path: "/editCatalogManage",
        name: "editCatalogManage",
        meta: {
          title: "编辑目录管理",
        },
        hidden: true,
        component: () => import("@/views/database/catalogManage/edit.vue"),
      },
      {
        // 资料管理
        path: "/dataManage",
        name: "dataManage",
        meta: {
          title: "资料管理",
          keepAlive:true,
        },
        hidden: false,
        component: () => import("@/views/database/dataManage/index.vue"),
      },
      {
        // 新增资料
        path: "/createManage",
        name: "createManage",
        meta: {
          title: "新增资料",
        },
        hidden: true,
        component: () => import("@/views/database/createManage/index.vue"),
      },
      {
        // 查看
        path: "/showManage",
        name: "showManage",
        meta: {
          title: "查看",
        },
        hidden: true,
        component: () => import("@/views/database/showManage/index.vue"),
      },
      {
        // 图标库
        path: "/iconLibrary",
        name: "iconLibrary",
        meta: {
          title: "图标库",
        },
        hidden: false,
        component: () => import("@/views/database/iconLibrary/index.vue"),
      },
      {
        // 回收站
        path: "/recycle",
        name: "recycle",
        meta: {
          title: "回收站",
        },
        hidden: true,
        component: () => import("@/views/database/recycle/index.vue"),
      },
    ],
  },
];
