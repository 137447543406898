import http from "../http.interceptors";

const infectiousDiseaseApi = {
  /* 疾病防控 */
  // insertLink: (params) => http.post("/api/techdayconfig/save", params),

  // 疾病防控列表-查询
  getDiseaseLog: (params) => http.post("/contagion/report-record/page", params),

  //疾病防控-查看
  getDiseaselookover: (params) =>
    http.get("/contagion/report-record/getById/" + params.id),

  //疾病防控-审核
  getDiseaseComment: (params) => http.post("/contagion/comment/save", params),

  //疾病防控-上报
  getReportSave: (params) => http.post("/contagion/report-record/save", params),

  //疾病防控-上报-所在园所病例数
  getCountByLoginUser: (params) =>
    http.get("/contagion/report-record/getCountByLoginUser", { params }),

  ///修改疾病防控上报记录
  getReportUpdate: (params) =>
    http.post("contagion/report-record/updateById", params),

  //疾病防控-结束反馈
  getUpdateFeedback: (params) =>
    http.post("contagion/report-record/updateFeedback", params),

  //获取分公司/园校的对比数据
  getCompare: (params) =>
    http.get("/contagion/report-record/getCompare", { params }),

  //总部处理状态打回、通过接口
  getCommentSave: (params) => http.post("/contagion/comment/save", params),
};

export { infectiousDiseaseApi };
