/*
    系统设置路由
 */
export default [
  {
    path: "/workCalendar",
    meta: {
      title: "首页",
    },
    component: () => import("@/layout/Main"),
    hidden: false,
    children: [
      {
        // 首页
        path: "/workCalendarIndex",
        name: "workCalendarIndex",
        meta: {
          title: "日历任务/待办任务",
          keepAlive:true,
        },
        hidden: false,
        component: () => import("@/views/workCalendar/workCalendarIndex.vue"),
      },
      {
        // 首页
        path: "/historicalNews",
        name: "historicalNews",
        meta: {
          title: "历史消息",
        },
        hidden: false,
        // src\views\HistoricalNews\index.vue
        component: () => import("@/views/historicalNews/index.vue"),
      },
      {
        // 首页
        path: "/addOperation",
        name: "addOperation",
        meta: {
          title: "新增待办",
        },
        hidden: false,
        // src\views\HistoricalNews\index.vue
        component: () => import("@/views/opratingManage/addOperation/index.vue"),
      },
    ],
  },
];
