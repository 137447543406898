import http from "../http.interceptors";

const morbidityApi = {
  /* 发病/患病率 */

  //查询发病/患病率总部
  getMorbidityInfoByMonthForHQ: (params) =>
    http.post("/mng/morbidity/morbidity/getMorbidityInfoByMonthForHQ", params),

  //查询发病/患病率分公司
  getMorbidityInfoByMonthForCom: (params) =>
    http.post("/mng/morbidity/morbidity/getMorbidityInfoByMonthForCom", params),

  //查询发病/患病率园校
  getMorbidityInfoByMonth: (params) =>
    http.post("/mng/morbidity/morbidity/getMorbidityInfoByMonth", params),

  //查询发病/患病率图表
  getMorbidityInfoByMonthForHQChart: (params) =>
    http.post(
      "/mng/morbidity/morbidity/getMorbidityInfoByMonthForHQChart",
      params
    ),

  //查询发病/患病率查看页
  getMorbidityQueryList: (params) =>
    http.post("/mng/morbidity/morbidity/queryList", params),

  //编辑
  getMorbidityQueryById: (params) =>
    http.get("/mng/morbidity/morbidity/getById/" + params),

  //删除
  getMorbidityRemove: (params) =>
    http.put(
      "/mng/morbidity/morbidity/remove?morbidityId=" + params.morbidityId
    ),

  //保存
  getSaveOrUpdate: (params) =>
    http.post("/mng/morbidity/morbidity/saveOrUpdate", params),
};

export { morbidityApi };
