/*
    系统设置路由
 */
export default [
  {
    path: "/foodExpense",
    meta: {
      title: "伙食费",
    },
    component: () => import("@/layout/Main"),
    hidden: false,
    children: [
      {
        // 执行情况总部
        path: "/foodExpenseIndex",
        name: "foodExpenseIndex",
        meta: {
          title: "执行情况/总部",
          keepAlive:true,
        },
        hidden: false,
        component: () => import("@/views/foodExpense/foodExpenseIndex.vue"),
      },
      {
        // 执行情况分公司
        path: "/foodExpenseCompany",
        name: "foodExpenseCompany",
        meta: {
          title: "执行情况/分公司",
          keepAlive:true,
        },
        hidden: false,
        component: () => import("@/views/foodExpense/foodExpenseCompany.vue"),
      },
      {
        // 执行情况园校
        path: "/foodExpenseSchool",
        name: "foodExpenseSchool",
        meta: {
          title: "执行情况/园校",
          keepAlive:true,
        },
        hidden: false,
        component: () => import("@/views/foodExpense/foodExpenseSchool.vue"),
      },
      {
        //执行情况分公司详情
        path: "/foodExpenseDetailCompany",
        name: "foodExpenseDetailCompany",
        meta: {
          title: "园校详情",
        },
        hidden: true,
        component: () =>
          import("@/views/foodExpense/foodExpenseDetailCompany.vue"),
      },
      {
        // 执行情况园校详情
        path: "/foodExpenseDetailSchool",
        name: "foodExpenseDetailSchool",
        meta: {
          title: "详情",
        },
        hidden: true,
        component: () =>
          import("@/views/foodExpense/foodExpenseDetailSchool.vue"),
      },
    ],
  },
];
