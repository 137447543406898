import http from "../http.interceptors";
const opratingApi = {
    //获取运营项目列表
    getOperateList: (params) => http.post("/operate/item/tree", params),
    // 添加运营项目
    operateSave: (params) => http.post("/operate/item/save", params),
    // 编辑运营项目
    operateEdit: (params) => http.post("/operate/item/updateById", params),
    // 删除运营项目
    operateDel: (params) => http.post("/operate/item/remove/" + params),
    // 运营项目详情
    operateDeTail: (params) => http.get("/operate/item/getById/" + params),
    // 关联资料库
    getFileByDirectoryIdForTree: () => http.get("/dataDirectory/data-file/getFileByDirectoryIdForTree"),
    // 运营项目排序
    updateSort: (params) => http.post("/operate/item/updateSort", params),
    // 运营模板
    getWorkTemplate: (params) => http.get("/operate/work-template/page", { params }),
    // 添加运营模板
    workTemplateSave: (params) => http.post("/operate/work-template/save", params),
    // 运营模板验证名称是否重复
    workTemplateCheckName: (params) => http.post("/operate/work-template/checkName", params),
    // 删除运营模板
    workTemplateDel: (params) => http.post("/operate/work-template/remove/" + params),
    // 编辑运营项目
    workTemplateEdit: (params) => http.post("/operate/work-template/updateById", params),
    // 运营项目详情
    workTemplateDetail: (params) => http.get("/operate/work-template/getById/" + params),
    // 运营项目编辑获取详情1
    workTemplateEditDetail1: (params) => http.get("/operate/work-template/getItemsById/" + params),
    // 运营项目编辑获取详情2
    workTemplateEditDetail2: (params) => http.get("/operate/work-template/getBasicById/" + params),
    // 运营任务列表
    taskList: (params) => http.post("/operate/task/page", params),
    // 新增运营任务
    taskListAdd: (params) => http.post("/operate/task/save", params),
    // 新增运营任务
    taskListPush: (params) => http.post("/operate/task/save", params),
    // 删除运营任务
    taskListDel: (params) => http.post("/operate/task/remove/" + params),
    // 终止运营任务
    taskListTerminate: (params) => http.post("/operate/task/terminate/" + params),
    // 修改运营任务
    taskListEdit: (params) => http.post("/operate/task/updateById", params),
    // 运营任务详情
    taskListDetail: (params) => http.get("/operate/task/getById/" + params),
    // 提前发布
    taskListPublish: (params) => http.post("/operate/task/publish/" + params),
    // 添加运营任务的运营模板列表，不分页
    getWorkTemplateList: (params) => http.get("/operate/work-template/list", params),
    // 待办任务列表
    getTaskDetailList: (params) => http.get("/operate/task-detail/page", { params }),
    // 待办任务详情
    getTaskDetail: (params) => http.get("/operate/task-detail/getById/" + params),
    // 待办任务详情 操作记录
    getTaskDetailOperate: (params) => http.get("/operate/task-detail/getHistory/" + params),
    // 待办任务详情 督办记录
    getTaskDetailSupervise: (params) => http.get("/operate/supervision-record/getByTask/" + params),
    // 园校和分公司  分公司：NODE_ORG 园校：ODE_SCHOOL
    getCompanyDept: (params) => http.get("/mng/dept/getCompanyDept", { params }),
    // 添加督办记录
    supervisionSave: (params) => http.post("/operate/supervision-record/save", params),
    //  查询超时反馈批示 
    timeoutInstructdDetail: (params) => http.post("/operate/timeout-instruct-record/getById/" + params),
    //  保存审批
    timeoutInstructSave: (params) => http.post("/operate/timeout-instruct-record/save", params),
    // 待办任务办理 
    taskExecute: (params) => http.post("/operate/task-execute/save", params),
    // 判断办理是否必填和文件类型 
    getCompleteSet: (params) => http.get("/operate/task-detail/getCompleteSet/" + params),
    // 保存超时反馈 /
    timeoutRecordSave: (params) => http.post("/operate/timeout-record/save", params),
    // 上传附件
    uploadFile: (params) => http.post("/dataDirectory/data-attachment/uploadFile", params),
    // 保存自定义任务。
    saveSelf: (params) => http.post("/operate/task/saveSelf", params),
    // 查询是否是园长
    getUserByPostLabel: (params) => http.get("/mng/post/getUserByPostLabel", { params }),
    // 查询是否是园长
    getUserByName: (params) => http.get("/mng/loginuser/getUserByName", { params }),
    // /loginuser/getUserByName

}
export { opratingApi };