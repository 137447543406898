import http from "../http.interceptors";

const foodExpenseApi = {
  /* 伙食费 */

  // 伙食费-总部查看
  getGroupPageLog: (params) =>
    http.get("/mng/mealExpenses/meal-expenses/groupPage", { params }),

  // 伙食费-分公司查看
  getBranchPageLog: (params) =>
    http.get("/mng/mealExpenses/meal-expenses/branchPage", { params }),

  // 伙食费-园校查看
  getParkPageLog: (params) =>
    http.get("/mng/mealExpenses/meal-expenses/parkPage", { params }),
	
  // 伙食费-同步
  getSyncMeal: (params) =>
    http.get("/mng/mealExpenses/meal-expenses/syncMeal", { params }),
	

  //   /mealExpenses/meal-expenses/groupPage（总部查看）
  // /mealExpenses/meal-expenses/branchPage（分公司查看）
  // /mealExpenses/meal-expenses/parkPage（园校查看）
};

export { foodExpenseApi };
