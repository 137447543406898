/*
    系统设置路由
 */
export default [
  {
    path: "/supervise",
    meta: {
      title: "督导管理",
    },
    component: () => import("@/layout/Main"),
    hidden: false,
    children: [
      {
        // 待办任务
        path: "/agencyTask",
        name: "agencyTask",
        meta: {
          title: "任务列表/总部",
          keepAlive:true
        },
        hidden: false,
        component: () => import("@/views/supervise/agencyTask/index.vue"),
      },
      {
        // 待办任务-分公司
        path: "/agencyTaskCompany",
        name: "agencyTaskCompany",
        meta: {
          title: "任务列表/分公司",
          keepAlive:true
        },
        hidden: false,
        component: () => import("@/views/supervise/agencyTask/agencyTaskCompany.vue"),
      },
      {
        // 待办任务-园校
        path: "/agencyTaskSchool",
        name: "agencyTaskSchool",
        meta: {
          title: "任务列表/园校",
          keepAlive:true
        },
        hidden: false,
        component: () => import("@/views/supervise/agencyTask/agencyTaskSchool.vue"),
      },
      {
        // 待办任务-评分
        path: "/agencyTaskScoreDetail",
        name: "agencyTaskScoreDetail",
        meta: {
          title: "评分",
        },
        hidden: true,
        component: () => import("@/views/supervise/agencyTask/scoreDetail.vue"),
      },
      {
        // 待办任务-查看
        path: "/agencyTaskViewDetail",
        name: "agencyTaskViewDetail",
        meta: {
          title: "查看",
        },
        hidden: true,
        component: () => import("@/views/supervise/agencyTask/viewDetail.vue"),
      },
      {
        // 待办任务-查看总评
        path: "/agencyTaskTotalScoreDetail",
        name: "agencyTaskTotalScoreDetail",
        meta: {
          title: "查看总评",
        },
        hidden: true,
        component: () => import("@/views/supervise/agencyTask/totalScoreDetail.vue"),
      },
      {
        // 待办任务-查看分享
        path: "/agencyTaskShareViewDetail",
        name: "agencyTaskShareViewDetail",
        meta: {
          title: "查看分享",
        },
        hidden: true,
        component: () => import("@/views/supervise/agencyTask/shareViewDetail.vue"),
      },
      {
        // 督导任务
        path: "/superviseTask",
        name: "superviseTask",
        meta: {
          title: "督导任务发布/总部",
          keepAlive:true
        },
        hidden: false,
        component: () => import("@/views/supervise/superviseTask/index.vue"),
      },
      {
        // 督导任务-分公司
        path: "/superviseTaskCompany",
        name: "superviseTaskCompany",
        meta: {
          title: "督导任务发布/分公司",
          keepAlive:true
        },
        hidden: false,
        component: () => import("@/views/supervise/superviseTask/superviseTaskCompany.vue"),
      },
      {
        // 督导任务-编辑/详情
        path: "/superviseTaskDetail",
        name: "superviseTaskDetail",
        meta: {
          title: "督导任务发布-编辑",
        },
        hidden: true,
        component: () => import("@/views/supervise/superviseTask/detail.vue"),
      },
      {
        // 督导项目
        path: "/superviseProject",
        name: "superviseProject",
        meta: {
          title: "督导项目/总部",
          keepAlive:true
        },
        hidden: false,
        component: () => import("@/views/supervise/superviseProject/index.vue"),
      },
      {
        // 督导项目
        path: "/superviseProjectCompany",
        name: "superviseProjectCompany",
        meta: {
          title: "督导项目/分公司",
          keepAlive:true
        },
        hidden: false,
        component: () => import("@/views/supervise/superviseProject/superviseProjectCompany.vue"),
      },
      {
        // 督导项目-编辑
        path: "/superviseProjectDetail",
        name: "superviseProjectDetail",
        meta: {
          title: "督导项目新增",
        },
        hidden: true,
        component: () => import("@/views/supervise/superviseProject/detail.vue"),
      },
      {
        //督导模板
        path: "/superviseTemplate",
        name: "superviseTemplate",
        meta: {
          title: "督导模板/总部",
          keepAlive:true
        },
        hidden: false,
        component: () =>
          import("@/views/supervise/superviseTemplate/index.vue"),
      },
      {
        //督导模板
        path: "/superviseTemplateCompany",
        name: "superviseTemplateCompany",
        meta: {
          title: "督导模板/分公司",
          keepAlive:true
        },
        hidden: false,
        component: () =>
          import("@/views/supervise/superviseTemplate/superviseTemplateCompany.vue"),
      },
      {
        //督导模板-编辑
        path: "/superviseTemplateDetail",
        name: "superviseTemplateDetail",
        meta: {
          title: "督导模板编辑",
        },
        hidden: true,
        component: () =>
          import("@/views/supervise/superviseTemplate/detail.vue"),
      },
      {
        //亮点库
        path: "/lightspot",
        name: "lightspot",
        meta: {
          title: "总部亮点库",
          keepAlive:true
        },
        hidden: false,
        component: () => import("@/views/supervise/lightspot/index.vue"),
      },
      {
        //亮点库
        path: "/lightspotCompany",
        name: "lightspotCompany",
        meta: {
          title: "分公司亮点库",
          keepAlive:true
        },
        hidden: false,
        component: () => import("@/views/supervise/lightspot/lightspotCompany.vue"),
      },
      {
        // 亮点库-查看
        path: "/lightspotDetail",
        name: "lightspotDetail",
        meta: {
          title: "亮点库-查看",
        },
        hidden: true,
        component: () => import("@/views/supervise/lightspot/detail.vue"),
      },
      {
        //督导报表
        path: "/superviseReport",
        name: "superviseReport",
        meta: {
          title: "督导报表",
          keepAlive:true
        },
        hidden: false,
        component: () => import("@/views/supervise/superviseReport/index.vue"),
      },
      {
        //督导报表-合并任务
        path: "/superviseReportDetail",
        name: "superviseReportDetail",
        meta: {
          title: "督导报表-合并任务",
        },
        hidden: true,
        component: () => import("@/views/supervise/superviseReport/detail.vue"),
      },
    ],
  },
];
