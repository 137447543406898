import http from "../http.interceptors";

const superviseApi = {
  // 督导项目列表
  getProjectTree: params => http.get('/supervise/project/getTree', { params }),
  //督导项目父级列表-分公司
  getBranchTree: params => http.get('/supervise/project/getBranchTree', { params }),
  // 督导项目编辑
  handleProjectFun: (url,params) => http.post(url, params),
  // 督导项目获取详情
  getProjectInfoById: id => http.get('/supervise/project/getById/'+id),
  // 督导项目删除
  removeProject: id => http.post('/supervise/project/remove/'+id),
  // 督导项目排序
  projectUpdateSort: params => http.post('/supervise/project/updateSort', params),
  // 督导项目改变状态
  projectUpdateStatus: params => http.get('/supervise/project/updateStatus', { params }),
  // 验证名称是否重复
  superviseTemplateCheckName: params => http.post('/supervise/template/checkName', params),
  // 督导模板列表
  getTemplateList: params => http.get('/supervise/template/page', { params }),
  // 督导模板编辑
  handleTemplateFun: (url,params) => http.post(url, params),
  // 督导模板删除
  removeTemplate: id => http.post('/supervise/template/remove/'+id),
  // 督导模板获取详情
  getTemplateInfoById: (id,params) => http.get('/supervise/template/getById/'+id, { params }),
  // 督导模板预览
  getTemplateViewInfoById: (id,params) => http.get('/supervise/template/showById/'+id, { params }),
  //督导模板-获取项目树
  getTemplateProjectTree: params => http.get('/supervise/project/getTempTree', { params }),
  // 督导模板-一级督导查询
  getProjectFirstLevelById: id => http.get('/supervise/template/showSelect/'+id),
  // 督导模板改变状态
  templateUpdateStatus: params => http.get('/supervise/template/updateStatus', { params }),

  // 督导任务发布列表
  getTaskList: params => http.get('/supervise/order-list/page', { params }),
  // 督导任务发布编辑
  handleTaskFun: (url,params) => http.post(url, params),
  // 督导任务发布删除
  removeTask: id => http.post('/supervise/order-list/remove/'+id),
  // 督导任务发布获取详情
  getTaskInfoById: id => http.get('/supervise/order-list/getById/'+id),
  //督导任务发布-获取模板
  getTaskTemplateList: params => http.get('/supervise/template/list', { params }),
  // 督导任务发布获取发布状态
  getTaskPublishStatus: id => http.post('/supervise/order-list/publishStatus/'+id),
  // 督导任务发布-发布失败错误列表
  getTaskFailList: params => http.get('/supervise/receiving-range/page', { params }),
  // 督导任务发布-错误列表刷新
  refreshTaskFailList: id => http.post('/supervise/receiving-range/getById/'+id),
  // 督导任务发布-错误列表批量刷新
  batchRefreshTaskFailList: id => http.post('/supervise/receiving-range/batchRefresh/'+id),
  // 督导任务发布-提前发布
  advanceTaskPublish: id => http.get('/supervise/order-list/advance/'+id),
  // 督导任务发布-重新发布
  republishTask: id => http.get('/supervise/order-list/republish/'+id),
  // 督导任务发布-查看
  getTaskViewInfoById: (id,params) => http.get('/supervise/order-list/showOrderList/'+id, { params }),
  // 督导任务发布-查看-一级督导查询
  getTaskProjectFirstLevelById: id => http.get('/supervise/order-list/showSelect/'+id),
  //督导任务发布-查看园校数据
  getTaskProjectSchoolInfoById: params => http.get('/supervise/order-list/checkPark', { params }),

  // 督导待办任务-全部任务列表
  getAgencyTaskAllList: params => http.get('/supervise/order/pageAll', { params }),
  // 督导待办任务-待办任务列表
  getAgencyTaskDoingList: params => http.get('/supervise/order/pageToDo', { params }),
  // 督导待办任务-已办任务列表
  getAgencyTaskFinishList: params => http.get('/supervise/order/pageDone', { params }),
  // 督导待办任务-查看任务
  getAgencyTaskInfo: (id,params) => http.get('/supervise/order/getById/'+id, { params }),
  // 督导待办任务-新增督办
  addSupervise: params => http.post('/supervise/records/save', params),
  // 督导待办任务-批量督办
  batchAddSupervise: params => http.post('/supervise/records/batchSave', params),
  // 督导待办任务-延长时间
  extendTaskDeadLine: params => http.post('/supervise/order/extendTime', params),
  // 督导待办任务-查看总评
  getAgencyTaskAllEvaluateInfo: id => http.get('/supervise/order/showComment/'+id),
  // 督导待办任务-总评保存
  updateAgencyTaskAllEvaluate: params => http.post('/supervise/all-comment/updateById', params),
  // 督导待办任务-撤销
  withdrawAgencyTask: id => http.get('/supervise/order/rollback/'+id),
  // 督导待办任务-评分查看
  getAgencyTaskScoreInfo: (id,params) => http.get('/supervise/order/showScore/'+id, { params }),
  // 督导待办任务-获取评分查看的督导内容列表
  getAgencyTaskScoreSuperviseInfo: id => http.get('/supervise/order/showSelect/'+id),
  // 督导待办任务-评分保存
  saveAgencyTaskScore: params => http.post('/supervise/record/save', params),
  // 督导待办任务-分享
  shareAgencyTask: params => http.post('/supervise/order/shareTask', params),
  // 督导待办任务-总部接收人刷新
  taskReceiverRefresh: params => http.post('/supervise/receiving-range/permRefresh', params),

  // 督导亮点库列表
  getLightapotList: params => http.get('/supervise/library/page', { params }),
  // 督导亮点库获取详情
  getLightapotInfo: id => http.get('/supervise/library/getById/'+id),
  //督导亮点库-加入亮点库
  saveLightspot: params => http.post('/supervise/library/save', params),
  //督导亮点库-删除亮点
  removeLightspot: id => http.post('/supervise/library/remove/'+id),
  // 督导亮点库-分享
  shareLightspot: params => http.post('/supervise/library/shareLibrary', params),

  // 督导报表列表
  getSuperviseReportList: params => http.get('/supervise/report/page', { params }),
  // 督导报表-导出
  exportSuperviseReportList: params => http.get('/supervise/report/exportList', { params }),
  // 督导报表-合并主题查询
  getTopicList: params => http.get('/supervise/report/getTopicList', { params }),
  // 督导报表-合并主题列表导出
  exportMergeTopicReportList: params => http.post('/supervise/report/exportMerge', params),
  // 督导报表-合并主题详情导出
  exportMergeTopicReportDetail: params => http.post('/supervise/report/exportMergeDetail', params),
  //督导报表-合并主题
  saveMergeTopic: params => http.post('/supervise/report/mergeTopic', params),
};

export { superviseApi };