/*
    系统设置路由
 */
export default [
  {
    path: "/insurance",
    meta: {
      title: "保险",
    },
    component: () => import("@/layout/Main"),
    hidden: false,
    children: [
      {
        // 幼儿校方责任险总部
        path: "/childInsurance",
        name: "childInsurance",
        meta: {
          title: "幼儿校方责任险/总部",
          keepAlive:true,
        },
        hidden: false,
        component: () => import("@/views/insurance/childInsurance/index.vue"),
      },
      {
        // 幼儿校方责任险分公司
        path: "/childInsuranceCompany",
        name: "childInsuranceCompany",
        meta: {
          title: "幼儿校方责任险/分公司",
          keepAlive:true,
        },
        hidden: false,
        component: () =>
          import("@/views/insurance/childInsurance/childInsuranceCompany.vue"),
      },
      {
        // 幼儿校方责任险园校
        path: "/childInsuranceSchool",
        name: "childInsuranceSchool",
        meta: {
          title: "幼儿校方责任险/园校",
          keepAlive:true,
        },
        hidden: false,
        component: () =>
          import("@/views/insurance/childInsurance/childInsuranceSchool.vue"),
      },
      {
        // 员工校方责任险总部
        path: "/employeeInsurance",
        name: "employeeInsurance",
        meta: {
          title: "员工校方责任险/总部",
          keepAlive:true,
        },
        hidden: false,
        component: () =>
          import("@/views/insurance/employeeInsurance/index.vue"),
      },
      {
        // 员工校方责任险分公司
        path: "/employeeInsuranceCompany",
        name: "employeeInsuranceCompany",
        meta: {
          title: "员工校方责任险/分公司",
          keepAlive:true,
        },
        hidden: false,
        component: () =>
          import(
            "@/views/insurance/employeeInsurance/employeeInsuranceCompany.vue"
          ),
      },
      {
        // 员工校方责任险园校
        path: "/employeeInsuranceSchool",
        name: "employeeInsuranceSchool",
        meta: {
          title: "员工校方责任险/园校",
          keepAlive:true,
        },
        hidden: false,
        component: () =>
          import(
            "@/views/insurance/employeeInsurance/employeeInsuranceSchool.vue"
          ),
      },

      {
        // 幼儿校责险出险总部
        path: "/childOutinsurance",
        name: "childOutinsurance",
        meta: {
          title: "幼儿校责险出险/总部",
          keepAlive:true,
        },
        hidden: false,
        component: () =>
          import("@/views/insurance/childOutinsurance/index.vue"),
      },

      {
        // 幼儿校责险出险分公司
        path: "/childOutinsuranceCompany",
        name: "childOutinsuranceCompany",
        meta: {
          title: "幼儿校责险出险/分公司",
          keepAlive:true,
        },
        hidden: false,
        component: () =>
          import(
            "@/views/insurance/childOutinsurance/childOutinsuranceCompany.vue"
          ),
      },

      {
        // 幼儿校责险出险园校
        path: "/childOutinsuranceSchool",
        name: "childOutinsuranceSchool",
        meta: {
          title: "幼儿校责险出险/园校",
          keepAlive:true,
        },
        hidden: false,
        component: () =>
          import(
            "@/views/insurance/childOutinsurance/childOutinsuranceSchool.vue"
          ),
      },
      {
        // 员工校责险出险总部
        path: "/employeeOutinsurance",
        name: "employeeOutinsurance",
        meta: {
          title: "员工校责险出险/总部",
          keepAlive:true,
        },
        hidden: false,
        component: () =>
          import("@/views/insurance/employeeOutinsurance/index.vue"),
      },
      {
        // 员工校责险出险分公司
        path: "/employeeOutinsuranceCompany",
        name: "employeeOutinsuranceCompany",
        meta: {
          title: "员工校责险出险/分公司",
          keepAlive:true,
        },
        hidden: false,
        component: () =>
          import(
            "@/views/insurance/employeeOutinsurance/employeeOutinsuranceCompany.vue"
          ),
      },
      {
        // 员工校责险出险园校
        path: "/employeeOutinsuranceSchool",
        name: "employeeOutinsuranceSchool",
        meta: {
          title: "员工校责险出险/园校",
          keepAlive:true,
        },
        hidden: false,
        component: () =>
          import(
            "@/views/insurance/employeeOutinsurance/employeeOutinsuranceSchool.vue"
          ),
      },

      {
        // 校责险出险
        path: "/lookAdd",
        name: "add",
        meta: {
          title: "新增出险",
        },
        hidden: true,
        component: () => import("@/views/insurance/lookAdd/add.vue"),
      },
      {
        // 查看图表
        path: "/insuranceBringcharts",
        name: "insuranceBringcharts",
        meta: {
          title: "查看图表",
        },
        hidden: true,
        component: () =>
          import("@/views/insurance/insuranceBringcharts/index.vue"),
      },
    ],
  },
];
