import { api } from "@/http/http.api";
import processSubjectTree from "@/utils/processSubjectTree";

export default {
  namespaced: true,

  state: {
    menuList: [],
    areaList: [], //地址列表
    organizationList: [], //组织结构
    fileTypeList: [],//附件类型
    targetTypeList: [],//目标类型
    targetStatusList: [],//目标状态
    postList: [],//岗位list
    breadcrumbList: [],//面包屑
  },

  mutations: {
    getMenuList(state, data) {
      state.menuList = data;
    },
    getAreaList(state, data) {
      state.areaList = data;
    },
    getOrganizationList(state, data) {
      console.log(state, "whahahahahhahahhahaah");
      state.organizationList = data;
    },
    getFileTypeList(state, data) {
      state.fileTypeList = data
    },
    getTargetTypeList(state, data) {
      state.targetTypeList = data
    },
    getTargetStatusList(state, data) {
      state.targetStatusList = data
    },
    getPostList(state, data) {
      state.postList = data
    },
    getBreadcrumbList(state, data) {
      state.breadcrumbList = data
    },
  },

  actions: {
    //仓库列表
    getMenuList(ctx) {
      api.getMenu().then((res) => {
        ctx.commit("getMenuList", res.data.data);
      });
    },
    // 地址列表
    getAreaList(ctx) {
      api.ecsAreaList().then((res) => {
        processSubjectTree(res.data.data.children);
        ctx.commit("getAreaList", res.data.data.children);
      });
    },
    //组织结构
    getOrganizationList(ctx) {
      api.getCompanyDept({ type: "NODE_ORG" }).then((res) => {
        ctx.commit("getOrganizationList", res.data.data);
      });
    },
    // 附件类型
    getFileTypeList(ctx){
        api.getDictionary({ type: 'attachment_type' }).then(res => {
            ctx.commit('getFileTypeList', res.data.data)
        })
    },
    // 目标类型
    getTargetTypeList(ctx){
      api.getDictionary({ type: 'target_type' }).then(res => {
          ctx.commit('getTargetTypeList', res.data.data)
      })
    },
    // 目标状态
    getTargetStatusList(ctx){
      api.getDictionary({ type: 'target_status' }).then(res => {
          ctx.commit('getTargetStatusList', res.data.data)
      })
    },
    // 岗位list
    getPostList(ctx){
      api.getDictionary({ type: 'post_label' }).then(res => {
          ctx.commit('getPostList', res.data.data)
      })
    },
  },
};
