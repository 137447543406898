import store from "@/store";
import router from "@/router";

export default () => {
  // let menuList = router.option.routes;
  let permissionsList = JSON.parse(store.state.permissions);
  let routes = [];
  let keepAlive = [];

  const getAllPage = (modItem, modRoutes) => {
    if (modItem.children) {
      modItem.children.forEach((menuItem) => {
        let flag = false;
        let itemInfo = {
          path: menuItem.url,
          name: menuItem.url.substr(1, menuItem.url.length),
          meta: {
            title: menuItem.name,
            buttons: [],
          },
        };
        if (menuItem.children) {
          flag = menuItem.children.every((item) => {
            return item.url.indexOf(":") != -1;
          });
          if (flag) {
            menuItem.children.map((info) => {
              itemInfo.meta.buttons.push(info.url);
            });
            itemInfo.meta.getButtons = true;
            modRoutes.children.push(itemInfo);
          } else {
            itemInfo.children = [];
            modRoutes.children.push(itemInfo);
            itemInfo.meta.getButtons = false;
            getAllPage(menuItem, modRoutes);
          }
        } else {
          modRoutes.children.push(itemInfo);
        }
      });
    }
  };
  if (permissionsList) {
    permissionsList.forEach((modItem, i) => {
      if ((modItem.url == "/systemSet" || modItem.url == "/database" || modItem.url == "/attendance" || modItem.url == "/insurance" || modItem.url == "/morbidity" || modItem.url == "/infectiousDisease" || modItem.url == "/foodExpense" || modItem.url == "/workCalendar" || modItem.url == "/targetManage" || modItem.url == "/opratingManage" || modItem.url == "/supervise" || modItem.url == "/dataBoard") && modItem.appId == "JY_OPERATION") {
        let flag = false;
        let modRoutes = {
          path: modItem.url,
          name: modItem.url.substr(1, modItem.url.length),
          meta: {
            title: modItem.name,
            buttons: [],
            getButtons: true,
          },
        };
        if (modItem.children) {
          flag = modItem.children.every((item) => {
            return item.url.indexOf(":") != -1;
          });
          if (flag) {
            modItem.children.map((info) => {
              modRoutes.meta.buttons.push(info.url);
            });
          } else {
            modRoutes.children = [];
            getAllPage(modItem, modRoutes);
          }
        }

        routes.push(modRoutes);
      }
      let levelInfo = permissionsList.find((info) => { return info.name === '层级标识' })
      let fLevelIndex = permissionsList.findIndex(((info) => { return info.url === 'org:f' }))
      let userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : null
      let authList = []
      if (levelInfo) {
        levelInfo.children.map((item) => {
          authList.push(item.url)
        })
      }
      if (fLevelIndex != -1) {
        authList.push('org:f')
      }
      if (userInfo) {
        userInfo.authList = authList
        store.commit("getUserInfo", userInfo);
        sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
      }
    });

  }
  store.state.keepAlive = keepAlive;
  console.log(routes);
  // router.addRoutes(routes)
  store.commit("common/getMenuList", routes);
};
