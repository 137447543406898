import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/door/login.vue";
import store from "@/store";
import WorkCalendar from "./modules/workCalendar"; //工作日历
import Supervise from "./modules/supervise"; //督导管理
import OpratingManage from "./modules/opratingManage"; //运营管理
import TargetManage from "./modules/targetManage"; //目标管理
import FoodExpense from "./modules/foodExpense"; //伙食费
import InfectiousDisease from "./modules/infectiousDisease"; //疾病防控
import Morbidity from "./modules/morbidity"; //发病/患病率
import Insurance from "./modules/insurance"; //保险
import Attendance from "./modules/attendance"; //出勤
import Database from "./modules/database"; //资料库
import DataBoard from "./modules/dataBoard"; //看板
import SystemSet from "./modules/systemSet"; //看板
import Admindict from "./modules/admin"; //字典
import { api } from "@/http/http.api";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    hidden: true,
    component: login,
  },
  {
    path: "/",
    name: "index",
    hidden: true,
    component: () => import("@/layout/layout"),
    children: [
      ...Admindict,
      ...WorkCalendar,
      ...Supervise,
      ...OpratingManage,
      ...TargetManage,
      ...FoodExpense,
      ...InfectiousDisease,
      ...Morbidity,
      ...Insurance,
      ...Attendance,
      ...Database,
      ...DataBoard,
      ...SystemSet,
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  // mode: "history",
  mode: "hash",
  base: process.env.BASE_URL,
  // scrollBehavior: () => ({ y: 0 }),
  scrollBehavior:(to,from,position)=>{
    if(position){
      return position
    }else{
      return {x:0,y:0}
    }
  },
  routes,
});

router.afterEach(() => {
  // api.getMyNotice({ isView: 0}).then(res=>{
  //     store.state.notice=res.data.data.total
  // })
});
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});
//解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
